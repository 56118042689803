import React from 'react'
import { router } from 'routes/routes'
import { APP_THEME_CONFIG } from 'constants/common/component/theme'
import { ConfigProvider } from 'antd'
import { RouterProvider } from 'react-router-dom'
import { Notifications } from 'components/notification/Notifications'
import { Modals } from 'components/modals/Modals'

const App: React.FC = () => (
  <ConfigProvider theme={APP_THEME_CONFIG}>
    <Modals />
    <Notifications />
    <RouterProvider router={router} />
  </ConfigProvider>
)

export default App
