import React, { useCallback } from 'react'
import classNames from 'classnames'
import { SortDirectionType } from 'react-virtualized'
import { OPTION_ICONS_BY_VALUE, SortOptionIcons } from 'components/common/data-display/Table/GridCell/SortOption/constans'
import { CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons'
import styles from './styles.module.css'

export interface Props {
  value: SortDirectionType
  isSelected?: boolean
  onSelect: (value?: SortDirectionType) => void
}

export const SortOption: React.FC<Props> = ({
  value,
  isSelected,
  onSelect
}) => {
  const handleClick = useCallback(
    () =>
      // Toggle sorting
      onSelect(isSelected ? undefined : value),
    [value, isSelected, onSelect]
  )

  const iconType = OPTION_ICONS_BY_VALUE[value]
  const Icon =
    iconType === SortOptionIcons.CaretUp
      ? CaretUpOutlined
      : CaretDownOutlined

  return (
    <Icon
      type={OPTION_ICONS_BY_VALUE[value]}
      className={classNames(styles.wrapper, {
        [styles.selected]: isSelected
      })}
      onClick={handleClick}
    />
  )
}
