import React, { useCallback } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { Input } from 'components/common/data-entry/Input'
import { Typography } from 'antd'
import { getHeadlineTailoringText } from 'redux/selectors/audio-pipeline'
import { setHeadlineTailoringText } from 'redux/actions/audio-pipeline'
import styles from './styles.module.css'

const { Text } = Typography

export const HeadlineTailoring: React.FC = () => {
  const dispatch = useDispatch()
  const { formatMessage } = useIntl()
  const headlineTailoringText = useSelector(getHeadlineTailoringText)
  const title = formatMessage({
    id: 'component.audio-pipeline.headline-tailoring.title'
  })
  const headlineTailoringPlaceholder = formatMessage({
    id: 'component.audio-pipeline.headline-tailoring.placeholder'
  })

  const handleHeadlineTailoringChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setHeadlineTailoringText(e.target.value))
  }, [dispatch])

  return (
    <div className={styles.wrapper}>
      <Text className={styles.label}>{title}</Text>
      <Input
        placeholder={headlineTailoringPlaceholder}
        value={headlineTailoringText}
        onChange={handleHeadlineTailoringChange}
      />
    </div>
  )
}