import React, { useCallback } from 'react'
import { useIntl } from 'react-intl'
import classNames from 'classnames'
import { TranscriptionLibraryItemDataKeys } from 'redux/reducers/transcription-library/types'
import { format } from 'date-fns'
import { formatPlaybackWordTime } from 'utils/audio-pipeline/audio-player'
import styles from './styles.module.css'

interface Props {
  id: string
  value?: any
  disabled?: boolean
}

export const TranscriptionTableCellValue: React.FC<Props> = ({
  id,
  value,
  disabled
}) => {
  const { formatMessage } = useIntl()
  const getFormattedValue = useCallback(() => {
    const isDate = id === TranscriptionLibraryItemDataKeys.CreatedAt
      || id === TranscriptionLibraryItemDataKeys.ExpireAt

    if (!value) {
      return formatMessage({
        id: 'component.transcription-library.table.column.no-data.label'
      })
    }

    if (id === TranscriptionLibraryItemDataKeys.Duration) {
      return formatPlaybackWordTime(value)
    }

    if (isDate) {
      return format(new Date(value), 'dd MMM yyyy HH:mm')
    }

    return value
  }, [id, value, formatMessage])


  return (
    <span className={classNames(styles.wrapper, styles[id], {
      [styles.disabled]: disabled
    })}>
      {getFormattedValue()}
    </span>
  )
}