import React, {
  useCallback,
  useEffect,
  useState
} from 'react'
import classNames from 'classnames'
import {
  AutoSizer,
  Table as BaseTable,
  Column,
  Index,
  SortDirectionType,
  TableCellDataGetter,
  TableCellRenderer,
  TableHeaderProps,
  RowMouseEventHandlerParams,
  TableRowProps,
  TableHeaderRowProps
} from 'react-virtualized'
import { GridTableHeaderProps, LabelFormatterProps, TableColumnType } from 'components/common/data-display/Table/types'
import {
  DEFAULT_HEADER_HEIGHT,
  DEFAULT_ROW_HEIGHT,
  DEFAULT_TABLE_HEIGHT
} from 'components/common/data-display/Table/constants'
import { GridCell } from 'components/common/data-display/Table/GridCell'
import { TableRow } from 'components/common/data-display/Table/TableRow'
import { TableHeaderRow } from 'components/common/data-display/Table/TableHeaderRow'
import { orderBy } from 'lodash'
import styles from './styles.module.css'

export interface Props {
  rows: any[]
  height: number
  headerHeight?: number
  rowHeight?: number
  headerClassName?: string
  headerCellClassName?: string
  isScrolling?: boolean
  scrollTop?: number
  onScroll?: (params: { scrollTop: number }) => void
  columns: TableColumnType[]
  cellDataGetter?: TableCellDataGetter
  cellRenderer?: TableCellRenderer
  defaultSortDirections?: SortDirectionType
  defaultSortBy?: string
  labelFormatter?: (props: LabelFormatterProps) => string
  onRowClick?: (params: RowMouseEventHandlerParams) => void
}

export const Table: React.FC<Props> = ({
  height = DEFAULT_TABLE_HEIGHT,
  isScrolling,
  rows,
  headerClassName,
  headerCellClassName,
  scrollTop,
  columns,
  cellDataGetter,
  cellRenderer,
  labelFormatter,
  defaultSortDirections,
  defaultSortBy,
  rowHeight = DEFAULT_ROW_HEIGHT,
  headerHeight = DEFAULT_HEADER_HEIGHT,
  onRowClick,
  onScroll
}) => {
  const [gridRows, setGridRows] = useState<TableColumnType[]>(rows)
  const [sortDirectionColumn, setSortDirectionColumn] = useState<
    SortDirectionType | undefined
  >(defaultSortDirections)

  const [sortByColumn, setSortByColumn] = useState<
    string | undefined
  >(defaultSortBy)

  const rowGetter = useCallback(
    ({ index }: Index) => gridRows[index] || {},
    [gridRows]
  )

  const handleSortChange = useCallback(
    ({ id, value }: { id?: string; value?: SortDirectionType }) => {
      setSortByColumn(id)
      setSortDirectionColumn(value)

      const orderedRows = value
        ? orderBy(rows, id, value?.toLowerCase() as any) // lower case because of orderBy arguments
        : rows

      setGridRows(orderedRows)
    },
    [rows]
  )

  const headerRenderer = useCallback(
    ({
      label,
      sortBy,
      dataKey,
      sortDirections
    }: GridTableHeaderProps) => {
      const sortDirection =
        dataKey === sortBy ? sortDirectionColumn : undefined

      return (
        <GridCell
          id={dataKey}
          isHeaderCell
          sortDirections={sortDirections}
          selectedSortDirection={sortDirection}
          onSortChange={handleSortChange}
        >
          <>{label}</>
        </GridCell>
      )
    },
    [handleSortChange, sortDirectionColumn]
  )

  const renderHeaderColumn = useCallback(
    ({
      id,
      label,
      width = 0,
      dataKey,
      sortDirections
    }: TableColumnType) => {
      const formattedLabel = labelFormatter
        ? labelFormatter({ label, dataKey })
        : label

      const headerRenderFn = (props: TableHeaderProps) =>
        headerRenderer({ ...props, sortDirections })

      return (
        <Column
          disableSort={!sortDirections}
          key={id}
          label={formattedLabel}
          width={width}
          maxWidth={width}
          dataKey={dataKey}
          flexGrow={1}
          className={classNames(styles.cell, headerCellClassName)}
          headerClassName={classNames(styles.headerCell, headerClassName)}
          cellDataGetter={cellDataGetter}
          cellRenderer={cellRenderer}
          headerRenderer={headerRenderFn}
        />
      )
    },
    [
      cellDataGetter,
      cellRenderer,
      labelFormatter,
      headerClassName,
      headerCellClassName,
      headerRenderer]
  )

  const renderRow = useCallback((props: TableRowProps) => {
    const {
      style,
      key,
      className,
      columns,
      index,
      isScrolling,
      rowData,
      onRowClick,
      onRowMouseOver,
      onRowMouseOut
    } = props

    return (
      <TableRow
        key={key}
        index={index}
        style={style}
        className={className}
        columns={columns}
        rowData={rowData}
        isScrolling={isScrolling}
        onRowClick={onRowClick}
        onRowMouseOver={onRowMouseOver}
        onRowMouseOut={onRowMouseOut}
      />
    )
  }, [])

  const renderHeaderRow = useCallback((props: TableHeaderRowProps) => {
    const {
      style,
      height,
      width,
      scrollbarWidth,
      className,
      columns
    } = props

    return (
      <TableHeaderRow
        style={style}
        className={className}
        height={height}
        width={width}
        scrollbarWidth={scrollbarWidth}
        columns={columns}
      />
    )
  }, [])

  useEffect(() => {
    handleSortChange({
      id: sortByColumn,
      value: sortDirectionColumn
    })
  }, [handleSortChange, sortByColumn, sortDirectionColumn])

  useEffect(() => {
    if (rows) {
      setGridRows(rows)
    }
  }, [rows])

  return (
    <AutoSizer disableHeight>
      {({ width }) => (
        <BaseTable
          autoHeight
          scrollTop={scrollTop}
          className={styles.wrapper}
          headerHeight={headerHeight}
          height={height}
          width={width}
          tableWidth={width}
          isScrolling={isScrolling}
          rowHeight={rowHeight}
          rowClassName={styles.row}
          rowCount={gridRows.length}
          rowGetter={rowGetter}
          rowRenderer={renderRow}
          headerRowRenderer={renderHeaderRow}
          headerClassName={styles.header}
          sortBy={sortByColumn}
          sortDirection={sortDirectionColumn}
          onRowClick={onRowClick}
          onScroll={onScroll}
        >
          {columns.map(renderHeaderColumn)}
        </BaseTable>
      )}
    </AutoSizer>
  )
}
