import React from 'react'
import { Card } from 'components/common/data-display/Card'
import { FileUploadField } from 'components/audio-pipeline/FileUpload/FileUploadField'
import { FileUploadSectionFooter } from 'components/audio-pipeline/FileUpload/Footer'
import config from 'config'
import styles from './styles.module.css'

export const FileUpload: React.FC = () => (
  <Card contentWrapperClassName={styles.content}>
    {config.uploadEnabled && <FileUploadField />}
    <FileUploadSectionFooter />
  </Card>
)