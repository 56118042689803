import React, { useCallback } from 'react'
import classNames from 'classnames'
import { Typography } from 'antd'
import styles from './styles.module.css'

const { Text } = Typography

export interface Props {
  title?: string
  className?: string
  titleClassName?: string
  extraClassName?: string
  extra?: React.ReactNode
  renderTitle?: (title?: string) => React.ReactNode
}

export const CardHeader: React.FC<Props> = ({
  title,
  titleClassName,
  className,
  extra,
  extraClassName,
  renderTitle,
}) => {
  const renderHeaderTitle = useCallback(() => {
    if (renderTitle) {
      return renderTitle(title)
    }

    return title && (
      <Text className={classNames(
        styles.title,
        titleClassName
      )}>
        {title}
      </Text>
    )
  }, [title, titleClassName, renderTitle])

  return (
    <div className={classNames(
      styles.wrapper,
      className
    )}>
      {renderHeaderTitle()}
      {extra && <div className={classNames(styles.extra, extraClassName)}>{extra}</div>}
    </div>
  )
}
