import React from 'react'
import classNames from 'classnames'
import type { TabsProps } from 'antd'
import { Tabs as BaseTabs } from 'antd'
import styles from './styles.module.css'

interface Props {
  items: TabsProps['items']
  activeKey: string
  className?: string
  onChange: (key: string) => void
}

export const Tabs: React.FC<Props> = ({
  items,
  activeKey,
  className,
  onChange
}) => (
  <BaseTabs
    items={items}
    className={classNames(styles.wrapper, className)}
    activeKey={activeKey}
    onChange={onChange}
  />
)