import React from 'react'
import { SummarisedText } from 'components/audio-pipeline/SummarisedText'
import { Headline } from 'components/audio-pipeline/Headline'
import { ArticleGeneration } from 'components/audio-pipeline/ArticleGeneration'
import { SEOTags } from 'components/audio-pipeline/SEOTags'
import styles from './styles.module.css'

export const TranscriptAdditionalContent: React.FC = () => (
    <div className={styles.wrapper}>
      <SummarisedText />
      <Headline />
      <SEOTags/>
      <ArticleGeneration />
    </div>
  )