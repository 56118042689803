import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CustomIconType } from 'components/common/general/CustomIcon/types'
import { IconButton } from 'components/common/general/IconButton'
import { setSearchSelectedMatchId } from 'redux/actions/transcript-search'
import {
  getMaxSelectedSearchMatchIndex,
  getSelectedSearchMatchIndex,
  getTranscriptionSearchMatchGroupIds
} from 'redux/selectors/transcript-search'
import styles from './styles.module.css'

export const PrevNextControls: React.FC = () => {
  const dispatch = useDispatch()
  const matchGroupIds = useSelector(getTranscriptionSearchMatchGroupIds)
  const maxSelectedIndex = useSelector(getMaxSelectedSearchMatchIndex)
  const currentSelectedMatchIndex = useSelector(getSelectedSearchMatchIndex)

  const handleNextClick = useCallback(() => {
    const nextSelectedId = matchGroupIds[currentSelectedMatchIndex + 1]

    if (nextSelectedId) {
      dispatch(setSearchSelectedMatchId(nextSelectedId))
    }

  }, [currentSelectedMatchIndex, matchGroupIds, dispatch])

  const handlePrevClick = useCallback(() => {
    const index = currentSelectedMatchIndex ? currentSelectedMatchIndex - 1 : 0
    const prevSelectedId = matchGroupIds[index]

    if (prevSelectedId) {
      dispatch(setSearchSelectedMatchId(prevSelectedId))
    }

  }, [currentSelectedMatchIndex, matchGroupIds, dispatch])

  return (
    <div className={styles.wrapper}>
      <IconButton
        iconType={CustomIconType.ArrowUp}
        disabled={!currentSelectedMatchIndex}
        onClick={handlePrevClick}
      />
      <IconButton
        iconType={CustomIconType.ArrowDown}
        disabled={currentSelectedMatchIndex === maxSelectedIndex}
        onClick={handleNextClick}
      />
    </div>
  )
}