import { all, takeLatest, select, put } from 'redux-saga/effects'
import { REPLACE_TEXT, SET_REPLACE_SEARCH_TEXT } from 'redux/actions/transcript-search/constants'
import { PayloadAction } from '@reduxjs/toolkit'
import { ReplaceTextPayload, SetTextPayload } from 'redux/actions/transcript-search/types'
import { TranscriptionWord } from 'components/audio-pipeline/TranscriptContent/types'
import {
  getSearchSelectedMatchGroupWords,
  getSearchWordMatches, getSelectedSearchMatchIndex,
  getTranscriptionReplaceSearchText,
  getTranscriptionReplaceText,
  getTranscriptionSearchMatchGroupIds, getTranscriptionSearchValidationError
} from 'redux/selectors/transcript-search'
import { revertEditedWord, setEditedWord } from 'redux/actions/audio-pipeline'
import { setSearchSelectedMatchId, setValidationError } from 'redux/actions/transcript-search'
import { escapeRegexSpecialChars } from 'utils/common/regex'
import { MAX_REPLACE_SEARCH_WORD_COUNT } from 'redux/sagas/transcript-search/constants'
import { intl } from 'utils/common/intl'

const handleReplaceText = function* ({ payload: { replaceAll } }: PayloadAction<ReplaceTextPayload>) {
  const selectedMatchWords: TranscriptionWord[] = yield select(getSearchSelectedMatchGroupWords)
  const allMatches: TranscriptionWord[] = yield select(getSearchWordMatches)
  const replaceSearchText: string = yield select(getTranscriptionReplaceSearchText)
  const matchGroupIds: string[] = yield select(getTranscriptionSearchMatchGroupIds)
  const selectedMatchIndex: number = yield select(getSelectedSearchMatchIndex)
  const replaceText: string = yield select(getTranscriptionReplaceText)
  const wordsToReplace = replaceAll ? allMatches : selectedMatchWords
  const escapedRegexTextPattern = escapeRegexSpecialChars(replaceSearchText)

  const regex = new RegExp(escapedRegexTextPattern, 'gi')

  for (let word of wordsToReplace) {
    const { id, text, originalText, startTime, endTime } = word
    const newText = text.replace(regex, replaceText)
    const shouldRevertToOriginal = newText === originalText

    yield put(setEditedWord({
      id,
      text: newText,
      startTime,
      endTime
    }))

    if (shouldRevertToOriginal) {
      yield put(revertEditedWord(id))
    }
  }

  if (!replaceAll) {
    const nextSelectedMatchId = matchGroupIds[selectedMatchIndex + 1]

    yield put(setSearchSelectedMatchId(nextSelectedMatchId))
  }
}

const handleChangeReplaceSearchText = function*({ payload: { text } }: PayloadAction<SetTextPayload>) {
  const words = text.split(/\s/)
  const error: string | null = yield select(getTranscriptionSearchValidationError)
  const isWordCountValid = words.length === MAX_REPLACE_SEARCH_WORD_COUNT

  if (!isWordCountValid) {
    yield put(setValidationError(intl.formatMessage({
      id: 'component.transcript-search.search-and-replace.search.error'
    })))
  }

  if (error && isWordCountValid) {
    yield put(setValidationError(null))
  }
}

export function* transcriptSearch() {
  yield all([
    takeLatest(REPLACE_TEXT, handleReplaceText),
    takeLatest(SET_REPLACE_SEARCH_TEXT, handleChangeReplaceSearchText)
  ])
}