import React, { useCallback } from 'react'
import { RootState } from 'redux/types/store'
import { Button } from 'components/common/general/Button'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { transcribe } from 'redux/actions/audio-pipeline'
import { getIsLoading } from 'redux/selectors/loaders'
import { getIsTranscribeDisabled } from 'redux/selectors/audio-pipeline'
import { Loaders } from 'redux/reducers/loaders/types'
import styles from './styles.module.css'

export const TranscribeButton: React.FC = () => {
  const dispatch = useDispatch()
  const isTranscribing = useSelector((state: RootState) => getIsLoading(state, Loaders.TranscriptionData))
  const isUploading = useSelector((state: RootState) => getIsLoading(state, Loaders.UploadFile))
  const isDisabled = useSelector(getIsTranscribeDisabled)
  const { formatMessage } = useIntl()

  const text = formatMessage({
    id: isTranscribing
      ? 'component.audio-pipeline.transcribe-cancel.button'
      : 'component.audio-pipeline.transcribe.button'
  })
  const isLoading = isUploading || isTranscribing

  const handleClick = useCallback(() => {
      dispatch(transcribe())
    }, [dispatch])

  return (
    <Button
      text={text}
      type='primary'
      size='large'
      danger={isLoading}
      disabled={isDisabled}
      className={styles.wrapper}
      onClick={handleClick}
    />
  )
}