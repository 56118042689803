import React from 'react'
import classNames from 'classnames'
import { TableHeaderRowProps } from 'react-virtualized/dist/es/Table'
import styles from './styles.module.css'

export const TableHeaderRow: React.FC<TableHeaderRowProps> = ({
  style,
  columns,
  className
}) => (
  <div
    style={style}
    className={classNames(styles.wrapper, className)}
  >
    {columns}
  </div>
)