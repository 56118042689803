export enum Loaders {
  TranscriptionData = 'transcriptionData',
  TranscriptionAudioUrl = 'transcriptionAudioUrl',
  Transcriptions = 'transcriptions',
  TranscriptionsPolling = 'transcriptionsPolling',
  Summarise = 'summarise',
  GenerateArticle = 'generateArticle',
  GenerateSEOTags = 'generateSEOTags',
  GenerateHeadline = 'generateHeadline',
  UploadFile = 'uploadFile',
  DeleteTranscription = 'deleteTranscription',
  EditTranscription = 'editTranscription'
}

export type LoadersStateType = Record<Loaders, boolean>