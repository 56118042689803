import React, { useCallback } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import classNames from 'classnames'
import { CustomIconType } from 'components/common/general/CustomIcon/types'
import { DEFAULT_ROWS_COUNT } from 'components/common/data-entry/TextArea/constants'
import { Input, Typography } from 'antd'
import { Button } from 'components/common/general/Button'
import { Title } from 'components/common/general/Title'
import { CustomIcon } from 'components/common/general/CustomIcon'
import { IconButton } from 'components/common/general/IconButton'
import { copyToClipboard } from 'redux/actions/audio-pipeline'
import styles from './styles.module.css'

const { Text } = Typography
const { TextArea: BaseTextArea } = Input

interface Props {
  value?: string
  title?: string
  description?: string
  placeholder?: string
  className?: string
  rows?: number
  shouldRenderSubmitButton?: boolean
  shouldRenderCopyButton?: boolean
  isSubmitDisabled?: boolean
  isLoading?: boolean
  readOnly?: boolean
  submitButtonText?: string
  submitButtonClassName?: string
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void
  onSubmit?: () => void
}

export const TextArea: React.FC<Props> = ({
  title,
  value,
  description,
  placeholder,
  className,
  submitButtonText,
  submitButtonClassName,
  isSubmitDisabled,
  isLoading,
  readOnly,
  shouldRenderSubmitButton,
  shouldRenderCopyButton,
  onChange,
  onSubmit,
  rows = DEFAULT_ROWS_COUNT
}) => {
  const dispatch = useDispatch()
  const { formatMessage } = useIntl()
  const submitText = submitButtonText || formatMessage({
    id: 'component.common.submit.text'
  })

  const handleCopyText = useCallback(() => {
    if (value) {
      dispatch(copyToClipboard(value))
    }
  }, [dispatch, value])

  return (
    <div className={classNames(styles.wrapper, className)}>
      {title && <Title text={title} />}
      <div className={styles.content}>
        <BaseTextArea
          readOnly={readOnly}
          className={classNames(styles.textarea, {
            [styles.withCopyButton]: shouldRenderCopyButton
          })}
          rows={rows}
          value={value}
          placeholder={placeholder}
          onChange={onChange}
        />
        {description && <Text className={styles.description}>{description}</Text>}
        {shouldRenderSubmitButton && (
          <Button
            type='primary'
            size='large'
            icon={<CustomIcon type={CustomIconType.AiGenerate} /> }
            className={classNames(styles.submit, submitButtonClassName)}
            text={submitText}
            disabled={isSubmitDisabled}
            loading={isLoading}
            onClick={onSubmit}
          />
        )}
        {shouldRenderCopyButton &&
          <IconButton
            className={styles.copyButton}
            iconType={CustomIconType.Copy}
            onClick={handleCopyText}
          />
        }
      </div>
    </div>
  )
}