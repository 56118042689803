import React from 'react'
import classNames from 'classnames'
import { CustomIconType } from 'components/common/general/CustomIcon/types'
import { Tag } from 'antd'
import { CustomIcon } from 'components/common/general/CustomIcon'
import { IconButton } from 'components/common/general/IconButton'
import styles from './styles.module.css'

interface Props {
  name: string
  isProcessing?: boolean
  hasError?: boolean
  onClose?: () => void
}

export const FileName: React.FC<Props> = ({
  name,
  isProcessing,
  hasError,
  onClose
}) => (
  <Tag
    className={classNames(styles.wrapper, {
      [styles.error]: hasError
    })}
    icon={<CustomIcon
      className={styles.icon}
      type={CustomIconType.Attachment}
    />}
    closeIcon={!isProcessing &&
      <IconButton
        className={styles.closeIcon}
        iconType={CustomIconType.Close}
      />}
    onClose={onClose}
  >
    {name}
  </Tag>
)