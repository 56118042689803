import React from 'react'
import { CustomIcon } from 'components/common/general/CustomIcon'
import { CustomIconType } from 'components/common/general/CustomIcon/types'
import { useOktaAuth } from '@okta/okta-react'
import { Typography } from 'antd'
import styles from './styles.module.css'

const { Text } = Typography

export const UserProfile: React.FC = () => {
  const { authState } = useOktaAuth()

  if (!authState?.idToken) {
    return null
  }

  const { email } = authState.idToken.claims

  return (
    <div className={styles.wrapper}>
      <CustomIcon type={CustomIconType.User} className={styles.icon} />
      <Text className={styles.text}>{email}</Text>
    </div>
  )
}