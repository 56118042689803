import React, { MouseEventHandler } from 'react'
import classNames from 'classnames'
import { Button as BaseButton } from 'antd'
import { BaseButtonProps } from 'antd/es/button/button'
import styles from './styles.module.css'

interface Props extends BaseButtonProps {
  text?: string
  onClick?: MouseEventHandler
}

export const Button: React.FC<Props> = ({
  text,
  variant,
  type,
  size,
  disabled,
  loading,
  danger,
  icon,
  className,
  onClick
}) => (
  <BaseButton
    type={type}
    size={size}
    variant={variant}
    icon={icon}
    loading={loading}
    disabled={disabled}
    danger={danger}
    className={classNames(styles.wrapper, className)}
    onClick={onClick}
  >
    {text}
  </BaseButton>
)