import { createBrowserRouter } from 'react-router-dom'
import { PrivateRoute } from 'components/common/auth/PrivateRoute'
import { Index } from 'routes/Index'
import { Transcript } from 'routes/Transcript'
import { Routes } from 'routes/constants'
import { Login } from 'routes/Login'
import { AuthLayout } from 'components/layouts/auth/AuthLayout'
import { DefaultLayout } from 'components/layouts/default/DefaultLayout'

export const router = createBrowserRouter([
  {
    element: <AuthLayout />,
    children: [
      {
        path: Routes.Index,
        element: <DefaultLayout />,
        children: [
          {
            path: Routes.Index,
            element: (
              <PrivateRoute>
                <Index />
              </PrivateRoute>
            )
          },
          {
            path: Routes.Transcript,
            element: (
              <PrivateRoute>
                <Transcript />
              </PrivateRoute>
            )
          }
        ]
      },
      {
        path: Routes.Login,
        element: <Login />
      }
    ]
  }
])
