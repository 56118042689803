import React from 'react'
import { Input } from 'components/common/data-entry/Input'
import classNames from 'classnames'
import styles from './styles.module.css'

interface Props {
  value?: string
  className?: string
  inputClassName?: string
  placeholder?: string
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}

export const SearchInput: React.FC<Props> = ({
  value,
  placeholder,
  className,
  inputClassName,
  onChange
}) => (
  <div className={classNames(styles.wrapper, className)}>
    <Input
      autoFocus
      value={value}
      placeholder={placeholder}
      className={classNames(styles.input, inputClassName)}
      onChange={onChange}
    />
  </div>
)