import React from 'react'
import { WordTooltipContent } from 'components/audio-pipeline/TranscriptContent/WordTooltipContent'
import styles from './styles.module.css'

interface Props {
  text: string
  wordId: string
  startTime: number
  endTime: number
  onMouseOver: () => void
  onMouseOut: () => void
}

export const WordTooltip: React.FC<Props> = ({
  text,
  wordId,
  startTime,
  endTime,
  onMouseOver,
  onMouseOut
}) => (
  <div
    onMouseOver={onMouseOver}
    onMouseOut={onMouseOut}
    className={styles.tooltip}
  >
    <WordTooltipContent
      id={wordId}
      text={text}
      startTime={startTime}
      endTime={endTime}
    />
  </div>
)