import React, { useCallback } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { Section } from 'components/common/data-display/Section'
import { TextArea } from 'components/common/data-entry/TextArea'
import { getFormattedTranscribedText, getSummarisedText } from 'redux/selectors/audio-pipeline'
import { summarise } from 'redux/actions/audio-pipeline'
import { RootState } from 'redux/types/store'
import { Loaders } from 'redux/reducers/loaders/types'
import { getIsLoading } from 'redux/selectors/loaders'

export const SummarisedText: React.FC = () => {
  const dispatch = useDispatch()
  const transcribedText = useSelector(getFormattedTranscribedText)
  const { formatMessage } = useIntl()
  const summarisedText = useSelector(getSummarisedText)
  const isLoading = useSelector((state: RootState) => getIsLoading(state, Loaders.Summarise))

  const title = formatMessage({
    id: 'component.audio-pipeline.summarised-text.title'
  })
  const submitText = formatMessage({
    id: 'component.audio-pipeline.additional-output.generate.submit'
  })
  const reSubmitText = formatMessage({
    id: 'component.audio-pipeline.additional-output.generate.resubmit'
  })

  const buttonText = summarisedText ? reSubmitText : submitText

  const handleSubmit = useCallback(() => {
    dispatch(summarise())
  }, [dispatch])

  return (
    <Section>
      <TextArea
        readOnly
        title={title}
        value={summarisedText}
        shouldRenderCopyButton={!!summarisedText}
        shouldRenderSubmitButton
        isSubmitDisabled={!transcribedText}
        isLoading={isLoading}
        submitButtonText={buttonText}
        onSubmit={handleSubmit}
      />
    </Section>
  )
}