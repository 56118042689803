import React from 'react'
import { LogoutButton } from 'components/common/auth/LogoutButton'
import { HelpLink } from 'components/layouts/common/Header/HelpLink'
import { UserProfile } from 'components/layouts/common/Header/UserProfile'
import styles from './styles.module.css'

export const UserInfo: React.FC = () => (
  <div className={styles.wrapper}>
    <HelpLink />
    <UserProfile />
    <LogoutButton />
  </div>
)