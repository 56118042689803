import React from 'react'
import { Delete } from 'components/audio-pipeline/Transcriptions/Modals/Delete'
import { Edit } from 'components/audio-pipeline/Transcriptions/Modals/Edit'

export const Modals: React.FC = () => (
  <>
    <Delete />
    <Edit />
  </>
)
