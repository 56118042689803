import { ReducersType } from 'redux/reducers/types'

export const FETCH_TRANSCRIPTIONS = `${ReducersType.TranscriptionLibrary}/fetchTranscriptions`
export const FETCH_TRANSCRIPTION = `${ReducersType.TranscriptionLibrary}/fetchTranscription`
export const CONSUME_TRANSCRIPTIONS = `${ReducersType.TranscriptionLibrary}/consumeTranscriptions`
export const UPDATE_TRANSCRIPTION = `${ReducersType.TranscriptionLibrary}/updateTranscription`
export const START_POLLING_TRANSCRIPTIONS = `${ReducersType.TranscriptionLibrary}/startPollingTranscriptions`
export const STOP_POLLING_TRANSCRIPTIONS = `${ReducersType.TranscriptionLibrary}/stopPollingTranscriptions`
export const DELETE_TRANSCRIPTION = `${ReducersType.TranscriptionLibrary}/deleteTranscription`
export const EDIT_TRANSCRIPTION = `${ReducersType.TranscriptionLibrary}/editTranscription`
export const SET_EDITED_TRANSCRIPTION_ID = `${ReducersType.TranscriptionLibrary}/setEditedTranscriptionId`
export const CONFIRM_DELETE_TRANSCRIPTION = `${ReducersType.TranscriptionLibrary}/confirmDeleteTranscription`
export const CANCEL_DELETE_TRANSCRIPTION = `${ReducersType.TranscriptionLibrary}/cancelDeleteTranscription`
export const CONFIRM_EDIT_TRANSCRIPTION = `${ReducersType.TranscriptionLibrary}/confirmEditTranscription`
export const CANCEL_EDIT_TRANSCRIPTION = `${ReducersType.TranscriptionLibrary}/cancelEditTranscription`
export const REMOVE_TRANSCRIPTION = `${ReducersType.TranscriptionLibrary}/removeTranscription`