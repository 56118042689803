import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  SearchBarContent
} from 'components/audio-pipeline/TranscriptPageTabs/TranscriptTextContent/TranscriptOptionsBar/SearchBar/SearchBarContent'
import {
  setSearchSelectedMatchId
} from 'redux/actions/transcript-search'
import { getSearchText } from 'redux/selectors/transcript-search'

export const TranscriptionSearchBar: React.FC = () => {
  const dispatch = useDispatch()
  const searchText = useSelector(getSearchText)

  useEffect(() => {
    if (!searchText) {
      dispatch(setSearchSelectedMatchId(null))
    }
  }, [dispatch, searchText])

  return (
    <SearchBarContent />
  )
}