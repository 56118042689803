const messages = {
  'component.transcript-search.search-input.placeholder': 'Search...',
  'component.transcript-search.search.label': 'Search',
  'component.transcript-search.search-match-count.label': '{current} of {total}',
  'component.transcript-search.search-and-replace.label': 'Replace one',
  'component.transcript-search.search-and-replace.replace-all': 'Replace all',
  'component.transcript-search.search-and-replace.search.error': 'You can only replace one word at a time',
}

export default messages
