import React from 'react'
import { Typography } from 'antd'
import styles from './styles.module.css'

const { Text } = Typography

interface Props {
  text: string
}

export const ModalTitle: React.FC<Props> = ({
  text
}) => (
  <Text className={styles.wrapper}>
    {text}
  </Text>
)