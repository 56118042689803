import { Colors } from 'constants/common/other/colors'
import { ThemeConfig } from 'antd'

export const APP_THEME_CONFIG: ThemeConfig = {
  token: {
    fontFamily: 'Roboto, Segoe UI, Oxygen, Helvetica Neue, sans-serif',
    borderRadius: 8,
    colorPrimary: Colors.dodgerBlue,
    colorPrimaryHover: Colors.scienceBlue,
    colorPrimaryActive: Colors.cobalt,
  },
  components: {
    Tabs: {
      horizontalItemGutter: 24,
      horizontalItemPadding: '8px 0',
      titleFontSize: 18,
      itemColor: Colors.aluminium,
      itemActiveColor: Colors.dodgerBlue,
      itemSelectedColor: Colors.dodgerBlue,
    },
    Input: {
      activeBorderColor: Colors.dodgerBlue,
      activeShadow: `0 0 4px 0 ${Colors.dodgerBlue}`,
      hoverBorderColor: Colors.dodgerBlue,
      paddingBlock: 8,
      paddingInline: 16,
      inputFontSize: 14
    },
    Button: {
      paddingBlock: 8,
      paddingInline: 16,
      paddingBlockLG: 16,
      paddingInlineLG: 24,
      fontWeight: 500,
      defaultBorderColor: Colors.cadetBlue,
      borderColorDisabled: Colors.mischka
    }
  }
}