import React from 'react'
import { useIntl } from 'react-intl'
import classNames from 'classnames'
import { TranscriptionStatus } from 'redux/reducers/transcription-library/types'
import { Loader } from 'components/common/feedback/Loader'
import styles from './styles.module.css'

interface Props {
  status: TranscriptionStatus
  label?: string | null
}

export const TranscriptionStatusValue: React.FC<Props> = ({
  status,
  label
}) => {
  const { formatMessage } = useIntl()
  const shouldRenderLoader = status === TranscriptionStatus.Transcribing
  const defaultLabel = formatMessage({
    id: `component.transcription-library.transcription.status.${status}`
  })
  const text = label || defaultLabel

  return (
    <span className={classNames(styles.wrapper, styles[status])}>
      {shouldRenderLoader && <Loader className={styles.loader} />}
      {text}
    </span>
  )
}