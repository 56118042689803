import React, { useCallback } from 'react'
import classNames from 'classnames'
import { TableRowProps } from 'react-virtualized/dist/es/Table'
import styles from './styles.module.css'

export const TableRow: React.FC<TableRowProps> = ({
  index,
  style,
  columns,
  className,
  rowData,
  onRowClick,
}) => {
  const handleClick = useCallback((event:React.MouseEvent<HTMLDivElement>) => {
    if (onRowClick) {
      onRowClick({ rowData, index, event})
    }
  }, [index, rowData, onRowClick])

  return (
    <div
      style={style}
      className={classNames(styles.wrapper, className, {
        [styles.clickable]: onRowClick
      })}
      onClick={handleClick}
    >
      {columns}
    </div>
  )
}