import React from 'react'
import classNames from 'classnames'
import { DropZoneDescription } from 'components/common/data-entry/FileUploadDropZone/DropZone/Description'
import { Status } from 'components/common/data-entry/FileUploadDropZone/DropZone/Status'
import { FileName } from 'components/common/data-entry/FileUploadDropZone/DropZone/FileName'
import { NoData } from 'components/common/other/NoData'
import { useSelector } from 'react-redux'
import { getFileError } from 'redux/selectors/audio-pipeline'
import styles from './styles.module.css'

interface Props {
  fileName?: string
  onRemoveFile?: () => void
  shouldRenderDescription?: boolean
  disabled?: boolean
  isProcessing?: boolean
}

export const DropZoneFileInfo: React.FC<Props> = ({
  fileName,
  disabled,
  isProcessing,
  shouldRenderDescription,
  onRemoveFile
}) => {
  const error = useSelector(getFileError)

  return (
    <div className={classNames(styles.wrapper, {
      [styles.disabled]: disabled,
      [styles.processing]: isProcessing
    })}>
      {!fileName && <NoData />}
      {shouldRenderDescription
        && <DropZoneDescription
          className={styles.description}
          isFileSelectDisabled={disabled}
        />
      }
      <Status />
      {fileName && <FileName
        name={fileName}
        isProcessing={isProcessing}
        hasError={!!error}
        onClose={onRemoveFile} />
      }
    </div>
  )
}