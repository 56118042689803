import { all, call, delay, fork, put, race, select, take, takeLatest } from 'redux-saga/effects'
import api from 'api'
import { Loaders } from 'redux/reducers/loaders/types'
import { PayloadAction } from '@reduxjs/toolkit'
import {
  FetchTranscriptionPayload,
  DeleteTranscriptionPayload,
  EditTranscriptionPayload,
  UpdateTranscriptionPayload
} from 'redux/actions/transcription-library/types'
import { LanguageSelectOptionIds } from 'components/audio-pipeline/FileUpload/LanguageSelect/types'
import { TranscriptionLibraryItem } from 'redux/reducers/transcription-library/types'
import { ConsumeSystemMessagesPayload } from 'redux/actions/system-messages/types'
import { ModalIds } from 'redux/reducers/modals/types'
import { NotificationType } from 'redux/reducers/notifications/types'
import { NotificationIds } from 'components/notification/Notifications/constants'
import {
  CONSUME_TRANSCRIPTIONS,
  FETCH_TRANSCRIPTION,
  FETCH_TRANSCRIPTIONS,
  START_POLLING_TRANSCRIPTIONS,
  STOP_POLLING_TRANSCRIPTIONS,
  CONFIRM_DELETE_TRANSCRIPTION,
  CANCEL_DELETE_TRANSCRIPTION,
  DELETE_TRANSCRIPTION,
  EDIT_TRANSCRIPTION,
  CONFIRM_EDIT_TRANSCRIPTION,
  CANCEL_EDIT_TRANSCRIPTION
} from 'redux/actions/transcription-library/constants'
import { POLLING_INTERVAL, POLLING_TIMEOUT } from 'redux/sagas/transcription-library/constants'
import { CONSUME_SYSTEM_MESSAGES } from 'redux/actions/system-messages/constants'
import { getSelectedLanguage } from 'redux/selectors/audio-pipeline'
import {
  getIsPollingTranscriptions,
  getPendingTranscriptionLibraryItemIds,
  getPendingTranscriptionLibraryItems,
} from 'redux/selectors/transcription-library'
import { startPollingSystemMessages, stopPollingSystemMessages } from 'redux/actions/system-messages'
import { startLoading, stopLoading } from 'redux/actions/loaders'
import { consumeTranscription, fetchTranscriptAudioUrl } from 'redux/actions/audio-pipeline'
import {
  consumeTranscriptions,
  startPollingTranscriptions,
  stopPollingTranscriptions,
  removeTranscription,
  setEditedTranscriptionId,
  updateTranscription
} from 'redux/actions/transcription-library'
import { mapTranscriptionResponse } from 'utils/audio-pipeline/mappers/transcription'
import { markItemsAsFailed } from 'utils/audio-pipeline/mappers/transcription-library'
import { filterErrorsMessages, getSystemMessageIds } from 'utils/audio-pipeline/mappers/system-messages'
import { openModal, closeModal } from 'redux/actions/modals'
import { addNotification } from 'redux/actions/notifications'
import { intl } from 'utils/common/intl'

const handleFetchTranscriptions = function* () {
  yield put(startLoading(Loaders.Transcriptions))

  try {
    const { data } = yield call(api.getTranscriptions)

    if (data?.length) {
      yield put(consumeTranscriptions(data))
    }
  } catch (err) {
    console.error(err)
  } finally {
    yield put(stopLoading(Loaders.Transcriptions))
  }
}

const handleConsumeTranscriptions = function* () {
  const pendingItemIds: string[] = yield select(getPendingTranscriptionLibraryItemIds)
  const isPollingStarted: boolean = yield select(getIsPollingTranscriptions)

  if (!pendingItemIds.length) {
    yield put(stopPollingTranscriptions())
    return
  }

  if (!isPollingStarted) {
    yield put(startPollingTranscriptions())
  }
}

const handleFetchTranscription = function* ({ payload: { id } }: PayloadAction<FetchTranscriptionPayload>) {
  yield put(startLoading(Loaders.TranscriptionData))
  yield put(fetchTranscriptAudioUrl(id))

  try {
    const lang: LanguageSelectOptionIds = yield select(getSelectedLanguage)

    const { data } = yield call(api.getTranscriptionById, {
      urlParams: {
        id
      }
    })


    if (data?.transcription) {
      yield put(consumeTranscription(mapTranscriptionResponse({
        transcription: data?.transcription,
        lang
      })))
    }
  } catch (err) {
    console.error(err)
  } finally {
    yield put(stopLoading(Loaders.TranscriptionData))
  }
}

function* handleTranscriptionsDataPolling() {
  while (true) {
    yield fork(handleFetchTranscriptions)
    yield delay(POLLING_INTERVAL)
  }
}

const handleStartPollingTranscriptions = function* () {
  const pendingItemIds: string[] = yield select(getPendingTranscriptionLibraryItemIds)

  yield put(startLoading(Loaders.TranscriptionsPolling))
  yield put(startPollingSystemMessages(pendingItemIds))

  yield race({
    polling: call(handleTranscriptionsDataPolling),
    cancel: take(STOP_POLLING_TRANSCRIPTIONS),
    timeout: delay(POLLING_TIMEOUT)
  })

  yield call(cancelPendingTranscriptions)
  yield put(stopPollingSystemMessages())
  yield put(stopLoading(Loaders.TranscriptionsPolling))
}

const cancelPendingTranscriptions = function* () {
  const pendingItems: TranscriptionLibraryItem[] = yield select(getPendingTranscriptionLibraryItems)
  const mappedItems = markItemsAsFailed(pendingItems)

  yield put(consumeTranscriptions(mappedItems))
}

const handleConsumeSystemMessages = function* ({ payload: { data } }: PayloadAction<ConsumeSystemMessagesPayload>) {
  const pendingItems: TranscriptionLibraryItem[] = yield select(getPendingTranscriptionLibraryItems)
  const errors = filterErrorsMessages(data)
  const errorIds = getSystemMessageIds(errors)
  const failedItems = pendingItems.filter(({ id }) => errorIds.includes(id))
  const mappedItems = markItemsAsFailed(failedItems)

  yield put(consumeTranscriptions(mappedItems))
}

function* handleConfirmDelete(id: string) {
  yield put(startLoading(Loaders.DeleteTranscription))

  try {
    yield call(api.deleteTranscription, { urlParams: { id } })

    yield put(removeTranscription(id))
  } catch (err) {
    yield put(addNotification({
      id: NotificationIds.DefaultError,
      type: NotificationType.Error,
      message: intl.formatMessage({
        id: 'component.error.default.message'
      }),
      duration: 0
    }))
  } finally {
    yield put(stopLoading(Loaders.DeleteTranscription))
  }
}

function* handleDeleteTranscription({ payload: { id } }: PayloadAction<DeleteTranscriptionPayload>) {
  yield put(openModal(ModalIds.DeleteTranscription))

  const { confirm } = yield race({
    confirm: take(CONFIRM_DELETE_TRANSCRIPTION),
    cancel: take(CANCEL_DELETE_TRANSCRIPTION)
  })

  if (confirm) {
    yield call(handleConfirmDelete, id)
  }

  yield put(closeModal(ModalIds.DeleteTranscription))
}

const handleConfirmEdit = function* ({ id, name }: UpdateTranscriptionPayload) {
  yield put(startLoading(Loaders.EditTranscription))

  try {
    yield call(api.editTranscription, {
      urlParams: { id },
      body: { name }
    })
    yield put(updateTranscription({
      id,
      name
    }))
  } catch (err) {
    yield put(addNotification({
      id: NotificationIds.DefaultError,
      type: NotificationType.Error,
      message: intl.formatMessage({
        id: 'component.error.default.message'
      }),
      duration: 0
    }))
  } finally {
    yield put(stopLoading(Loaders.EditTranscription))
  }
}

const handleEditTranscription = function* ({ payload: { id } }: PayloadAction<EditTranscriptionPayload>) {
  yield put(openModal(ModalIds.EditTranscription))

  yield put(setEditedTranscriptionId(id))

  const { confirm } = yield race({
    confirm: take(CONFIRM_EDIT_TRANSCRIPTION),
    cancel: take(CANCEL_EDIT_TRANSCRIPTION)
  })

  if (confirm) {
    const { payload: { name } } = confirm

    yield call(handleConfirmEdit, { id, name })
  }

  yield put(setEditedTranscriptionId(null))
  yield put(closeModal(ModalIds.EditTranscription))
}

export function* transcriptionLibrary() {
  yield all([
    takeLatest(FETCH_TRANSCRIPTIONS, handleFetchTranscriptions),
    takeLatest(FETCH_TRANSCRIPTION, handleFetchTranscription),
    takeLatest(START_POLLING_TRANSCRIPTIONS, handleStartPollingTranscriptions),
    takeLatest(CONSUME_TRANSCRIPTIONS, handleConsumeTranscriptions),
    takeLatest(CONSUME_SYSTEM_MESSAGES, handleConsumeSystemMessages),
    takeLatest(DELETE_TRANSCRIPTION, handleDeleteTranscription),
    takeLatest(EDIT_TRANSCRIPTION, handleEditTranscription)
  ])
}