import React from 'react'
import { useIntl } from 'react-intl'
import { Typography } from 'antd'
import { Loader } from 'components/common/feedback/Loader'
import styles from './styles.module.css'

const { Text } = Typography

interface Props {
  percent: number
}

export const UploadProgress: React.FC<Props> = ({
  percent
}) => {
  const { formatMessage } = useIntl()
  const uploadingText = formatMessage({
    id: 'component.audio-pipeline.transcribe-uploading.text'
  }, { percent })

  return (
    <div className={styles.wrapper}>
      <Loader className={styles.loader} />
      <Text className={styles.label}>{uploadingText}</Text>
    </div>
  )
}