import React, { useCallback } from 'react'
import { useIntl } from 'react-intl'
import { useOktaAuth } from '@okta/okta-react'
import { Button } from 'antd'
import styles from './styles.module.css'

export const LogoutButton: React.FC = () => {
  const { oktaAuth } = useOktaAuth()
  const { formatMessage } = useIntl()
  const text = formatMessage({
    id: 'component.common.auth.logout'
  })

  const handleClick = useCallback(() => {
    oktaAuth.signOut()
  }, [oktaAuth])
  
  return (
    <Button className={styles.wrapper} onClick={handleClick}>
      {text}
    </Button>
  )
}