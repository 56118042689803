import React from 'react'
import { useIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import { Typography } from 'antd'
import { CustomIcon } from 'components/common/general/CustomIcon'
import { CustomIconType } from 'components/common/general/CustomIcon/types'
import config from 'config'
import styles from './styles.module.css'

const { Text } = Typography

export const HelpLink = () => {
  const { formatMessage } = useIntl()
  const helpLink = formatMessage({
    id: 'component.common.help-link.text'
  })

  return (
    <Link
      className={styles.wrapper}
      to={config.links.newsTranscribeGuide}
      target='_blank'
    >
      <CustomIcon type={CustomIconType.Info} className={styles.icon} />
      <Text className={styles.text}>{helpLink}</Text>
    </Link>
  )
}