import React, { useCallback } from 'react'
import { SortDirectionType } from 'react-virtualized'
import { SortOption } from 'components/common/data-display/Table/GridCell/SortOption'
import styles from './styles.module.css'

export interface Props {
  options: SortDirectionType[]
  value?: SortDirectionType
  onChange: (value?: SortDirectionType) => void
}

export const SortOptions: React.FC<Props> = ({
  options,
  value,
  onChange
}) => {
  const renderOption = useCallback(
    (option: SortDirectionType) => (
      <SortOption
        key={option}
        value={option}
        isSelected={option === value}
        onSelect={onChange}
      />
    ),
    [value, onChange]
  )

  return (
    <div className={styles.wrapper}>{options.map(renderOption)}</div>
  )
}
