import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react'
import { useSelector } from 'react-redux'
import classNames from 'classnames'
import {
  TranscriptionSpeakerParagraph
} from 'components/audio-pipeline/TranscriptContent/types'
import {
  DEFAULT_LIST_HEIGHT,
  DEFAULT_LIST_ITEM_HEIGHT,
  DEFAULT_ROW_OVERSCAN_COUNT,
  LIST_SCROLL_TO_ALIGNMENT
} from 'components/audio-pipeline/TranscriptContent/constants'
import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  List,
  ListRowRenderer
} from 'react-virtualized'
import { SpeakerParagraph } from 'components/audio-pipeline/TranscriptContent/TranscriptParagraph'
import { NoData } from 'components/common/other/NoData'
import {
  getEditedWordId,
  getEditedWords,
  getPlayerCurrentWordId,
  getSelectedTranscriptActionTab,
  getTranscriptionParagraphs
} from 'redux/selectors/audio-pipeline'
import {
  getSearchSelectedMatchGroupWordIds,
  getSearchSelectedMatchParagraphIndex,
  getTranscriptionSearchMatchedWordIds
} from 'redux/selectors/transcript-search'
import debounce from 'lodash/debounce'
import isNull from 'lodash/isNull'
import styles from './styles.module.css'

export const TranscriptContent: React.FC = React.memo(() => {
  const data = useSelector(getTranscriptionParagraphs)
  const activeActionTab = useSelector(getSelectedTranscriptActionTab)
  const searchMatchParagraphIndex = useSelector(getSearchSelectedMatchParagraphIndex)
  const listRef = useRef<List>(null)
  const [scrollToIndex, setScrollToIndex] = useState<number>(0)
  const searchMatchedWordIds = useSelector(getTranscriptionSearchMatchedWordIds)
  const editedWordId = useSelector(getEditedWordId)
  const currentWordId = useSelector(getPlayerCurrentWordId)
  const editedWordsById = useSelector(getEditedWords)
  const selectedSearchGroupWords = useSelector(getSearchSelectedMatchGroupWordIds)

  const cache = useMemo(() => new CellMeasurerCache({
    defaultHeight: DEFAULT_LIST_ITEM_HEIGHT,
    fixedWidth: true
  }), [])

  useEffect(() => {
    cache.clearAll()
  }, [data, cache])

  const handleParagraphActive = useCallback((index: number) => {
    if (!searchMatchParagraphIndex) {
      setScrollToIndex(index)
    }
  }, [searchMatchParagraphIndex])

  const handleResize = useCallback(() => {
    cache.clearAll()
    listRef.current?.recomputeRowHeights()
    listRef.current?.forceUpdateGrid()
  }, [cache])

  const debouncedResize = useMemo(() => debounce(handleResize, 100), [handleResize])

  useEffect(() => {
    if (!isNull(searchMatchParagraphIndex)) {
      setScrollToIndex(searchMatchParagraphIndex)
    }
  }, [searchMatchParagraphIndex])

  useEffect(() => {
    window.addEventListener('resize', debouncedResize)
    return () => window.removeEventListener('resize', debouncedResize)
  }, [debouncedResize])

  const renderNoData = useCallback(() =>
      <NoData shouldRenderDescription className={styles.empty} />,
    [])

  const rowRenderer = useCallback<ListRowRenderer>(({ key, index, style, parent }) => {
    const {
      speaker,
      startTime,
      endTime,
      wordTimestamps
    }: TranscriptionSpeakerParagraph = data[index]

    return (
      <CellMeasurer
        cache={cache}
        key={key}
        parent={parent}
        rowIndex={index}
      >
        {({ registerChild }) => (
          // @ts-ignore
          <div ref={registerChild} style={style}>
            {index === 0 && <div className={styles.offset} />}
            <SpeakerParagraph
              key={key}
              index={index}
              speaker={speaker}
              startTime={startTime}
              endTime={endTime}
              words={wordTimestamps}
              className={classNames({
                [styles.last]: index === data.length - 1
              })}
              searchMatchedWordIds={searchMatchedWordIds}
              selectedSearchGroupWords={selectedSearchGroupWords}
              editedWordId={editedWordId}
              currentWordId={currentWordId}
              editedWordsById={editedWordsById}
              onParagraphActive={handleParagraphActive}
            />
          </div>
        )}
      </CellMeasurer>
    )

  }, [
    data,
    cache,
    editedWordId,
    currentWordId,
    handleParagraphActive,
    searchMatchedWordIds,
    selectedSearchGroupWords,
    editedWordsById
  ])

  return (
    <AutoSizer defaultHeight={DEFAULT_LIST_HEIGHT}>
      {({ width, height }) => (
        <List
          height={height}
          width={width}
          className={classNames(styles.list, {
            [styles.expanded]: activeActionTab
          })}
          scrollToIndex={scrollToIndex}
          scrollToAlignment={LIST_SCROLL_TO_ALIGNMENT}
          noRowsRenderer={renderNoData}
          rowCount={data.length}
          overscanRowCount={DEFAULT_ROW_OVERSCAN_COUNT}
          rowHeight={cache.rowHeight}
          rowRenderer={rowRenderer}
          ref={listRef}
        />
      )}
    </AutoSizer>
  )
})