export enum Colors {
  white = '#ffffff',
  black = '#000000',
  midnight = '#00112C',
  athensGray = '#f1f2f5',
  zircon = '#ECF1FF',
  gray = '#919191',
  doveGray = '#6A6A6A',
  hawkesBlue = '#D5E0FC',
  ceruleanBlue = '#3358CC',
  dodgerBlue = '#4F93FF',
  chathamsBlue = '#12387A',
  scienceBlue = '#0056DF',
  cobalt = '#003EA2',
  pattenBlue = '#D1E2FF',
  aluminium = '#AAB1BC',
  cadetBlue = '#A4AFC2',
  geyser = '#D1D8E3',
  mischka = '#D3D8E2'
}
