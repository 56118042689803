import React from 'react'
import { Tabs } from 'components/common/data-display/Tabs'
import { TabsProps } from 'antd'
import { TranscriptContentTabs } from 'components/audio-pipeline/TranscriptPageTabs/constants'
import { Card } from 'components/common/data-display/Card'
import { intl } from 'utils/common/intl'
import {
  TranscriptOptionsBar
} from 'components/audio-pipeline/TranscriptPageTabs/TranscriptTextContent/TranscriptOptionsBar'
import styles from './styles.module.css'

const TAB_ITEMS: TabsProps['items'] = [
  {
    key: TranscriptContentTabs.Transcript,
    label: intl.formatMessage({
      id: 'component.audio-pipeline.tabs.transcript.label'
    })
  },
  {
    key: TranscriptContentTabs.AdditionalOutput,
    label: intl.formatMessage({
      id: 'component.audio-pipeline.tabs.additional-output.label'
    })
  }
]

interface Props {
  onChange: (id: string) => void
  activeKey: string
}

export const TranscriptPageTabs: React.FC<Props> = ({
  activeKey,
  onChange
}) => {
  return (
    <div className={styles.wrapper}>
      <Card className={styles.cardWrapper}>
        <Tabs
          items={TAB_ITEMS}
          activeKey={activeKey}
          onChange={onChange}
        />
        {activeKey === TranscriptContentTabs.Transcript &&
          <TranscriptOptionsBar />}
      </Card>
    </div>
  )
}