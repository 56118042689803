import React  from 'react'
import { useSelector } from 'react-redux'
import { TranscriptSearchMode } from 'redux/reducers/transcript-search/types'
import {
  TranscriptSearchField
} from 'components/audio-pipeline/TranscriptPageTabs/TranscriptTextContent/TranscriptOptionsBar/SearchBar/SearchBarContent/SearchField'
import {
  TranscriptReplaceField
} from 'components/audio-pipeline/TranscriptPageTabs/TranscriptTextContent/TranscriptOptionsBar/SearchBar/SearchBarContent/ReplaceField'
import { getTranscriptionSearchMode } from 'redux/selectors/transcript-search'
import {
  SearchValidationError
} from 'components/audio-pipeline/TranscriptPageTabs/TranscriptTextContent/TranscriptOptionsBar/SearchBar/SearchValidationError'
import styles from './styles.module.css'

export const SearchBarContent: React.FC = () => {
  const mode = useSelector(getTranscriptionSearchMode)

  return (
    <div className={styles.wrapper}>
      <TranscriptSearchField />
      {mode === TranscriptSearchMode.SearchAndReplace
        && <TranscriptReplaceField />
      }
      {mode === TranscriptSearchMode.SearchAndReplace
        && <SearchValidationError />
      }
    </div>
  )
}