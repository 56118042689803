import React from 'react'
import { TabsProps } from 'antd'
import {
  TranscriptActionOptions
} from 'components/audio-pipeline/TranscriptPageTabs/TranscriptTextContent/TranscriptOptionsBar/types'
import { CustomIconType } from 'components/common/general/CustomIcon/types'
import {
  TabContent
} from 'components/audio-pipeline/TranscriptPageTabs/TranscriptTextContent/TranscriptOptionsBar/TabContent'
import { CustomIcon } from 'components/common/general/CustomIcon'
import { intl } from 'utils/common/intl'

export const TAB_ITEMS: TabsProps['items'] = [
  {
    key: TranscriptActionOptions.Search,
    label: intl.formatMessage({
      id: 'component.audio-pipeline.options.tabs.search.label'
    }),
    children: <TabContent selectedOption={TranscriptActionOptions.Search} />,
    icon: <CustomIcon type={CustomIconType.Search} />
  },
  {
    key: TranscriptActionOptions.SearchAndReplace,
    label: intl.formatMessage({
      id: 'component.audio-pipeline.options.tabs.search-and-replace.label'
    }),
    children: <TabContent selectedOption={TranscriptActionOptions.SearchAndReplace} />,
    icon: <CustomIcon type={CustomIconType.SearchAndReplace} />
  },
  {
    key: TranscriptActionOptions.Speakers,
    label: intl.formatMessage({
      id: 'component.audio-pipeline.options.tabs.speakers.label'
    }),
    children: <TabContent selectedOption={TranscriptActionOptions.Speakers} />,
    icon: <CustomIcon type={CustomIconType.User} />
  }
]