import React, { MouseEventHandler } from 'react'
import classNames from 'classnames'
import { CustomIcon } from 'components/common/general/CustomIcon'
import { CustomIconType } from 'components/common/general/CustomIcon/types'
import { Button } from 'antd'
import styles from './styles.module.css'

interface Props {
  iconType: CustomIconType
  disabled?: boolean
  className?: string
  onClick?: MouseEventHandler
}

export const IconButton: React.FC<Props> = ({
  iconType,
  disabled,
  className,
  onClick
}) => {
  return (
    <Button
      type='text'
      disabled={disabled}
      className={classNames(styles.wrapper, className)}
      icon={<CustomIcon className={styles.icon} type={iconType} />}
      onClick={onClick}
    />
  )
}