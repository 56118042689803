import React, { useCallback } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import { Button } from 'antd'
import { LoginBoxHeader } from 'components/common/auth/LoginBox/Header'
import { login } from 'redux/actions/auth'
import styles from './styles.module.css'

export const LoginBox: React.FC = () => {
  const dispatch = useDispatch()
  const { formatMessage } = useIntl()
  const btnText = formatMessage({
    id: 'component.auth.login.label'
  })

  const handleLogin = useCallback(() => {
    dispatch(login())
  }, [dispatch])

  return (
    <div className={styles.wrapper}>
      <LoginBoxHeader />
      <Button
        type='primary'
        className={styles.loginButton}
        onClick={handleLogin}
      >
        {btnText}
      </Button>
    </div>
  )
}