import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  TranscriptWordTextContent
} from 'components/audio-pipeline/TranscriptContent/TranscriptWord/WordTextContent'
import {
  getPlayerCurrentWordId
} from 'redux/selectors/audio-pipeline'
import { setPlayerSelectedWordId } from 'redux/actions/audio-pipeline'

interface Props {
  id: string
  text: string
  startTime: number
  endTime: number
  isPassed?: boolean
  isActive?: boolean
  isEditing?: boolean
  isEdited?: boolean
  isSearchSelected?: boolean
  isMatchSearch?: boolean
}

export const TranscriptWord: React.FC<Props> = React.memo(({
  id,
  text,
  isPassed,
  isActive,
  isMatchSearch,
  isSearchSelected,
  isEdited,
  isEditing,
  startTime,
  endTime
}) => {
  const dispatch = useDispatch()
  const currentWordId = useSelector(getPlayerCurrentWordId)

  useEffect(() => {
    if (isActive && currentWordId) {
      dispatch(setPlayerSelectedWordId(null))
    }
  }, [isActive, currentWordId, dispatch])

  return (
    <TranscriptWordTextContent
      id={id}
      text={text}
      startTime={startTime}
      endTime={endTime}
      isEdited={isEdited}
      isEditing={isEditing}
      isPassed={isPassed}
      isActive={isActive}
      isMatchSearch={isMatchSearch}
      isSearchSelected={isSearchSelected}
    />
  )
})