export enum ReducersType {
  Loaders = 'loaders',
  Errors = 'errors',
  AudioPipeline = 'audio-pipeline',
  Notifications = 'notifications',
  Auth = 'auth',
  SystemMessages = 'system-messages',
  TranscriptSearch = 'transcript-search',
  TranscriptionLibrary = 'transcription-library',
  Modals = 'modals',
}