import React, { useCallback } from 'react'
import { Input } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { EditWordButton } from 'components/audio-pipeline/TranscriptContent/WordTooltipContent/EditButton'
import {
  getEditedWordId,
  getEditedWordText
} from 'redux/selectors/audio-pipeline'
import { setEditedWordText } from 'redux/actions/audio-pipeline'
import styles from './styles.module.css'

interface Props {
  id: string
}

export const EditWord: React.FC<Props> = ({
  id
}) => {
  const dispatch = useDispatch()
  const editedWordId = useSelector(getEditedWordId)
  const editedWordText = useSelector(getEditedWordText)
  const isEditing = id === editedWordId

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setEditedWordText(e.target.value))
  }, [dispatch])

  return (
    <div className={styles.wrapper}>
      {isEditing &&
        <Input
          className={styles.input}
          value={editedWordText}
          onChange={handleChange}
        />
      }
      <EditWordButton id={id} />
    </div>
  )
}