import React, { useCallback, useMemo, useState } from 'react'
import classNames from 'classnames'
import { Componentify } from 'components/common/other/Componentify'
import { useDispatch, useSelector } from 'react-redux'
import {
  getSearchText
} from 'redux/selectors/transcript-search'
import { WordTooltip } from 'components/audio-pipeline/TranscriptContent/WordTooltip'
import { setPlayerSelectedWordId } from 'redux/actions/audio-pipeline'
import styles from './styles.module.css'

interface Props {
  id: string
  text: string
  startTime: number
  endTime: number
  isPassed?: boolean
  isActive?: boolean
  isEditing?: boolean
  isEdited?: boolean
  isMatchSearch?: boolean
  isSearchSelected?: boolean
}

export const TranscriptWordTextContent: React.FC<Props> = React.memo(({
  id,
  text,
  startTime,
  endTime,
  isPassed,
  isActive,
  isEditing,
  isEdited,
  isSearchSelected,
  isMatchSearch

}) => {
  const dispatch = useDispatch()
  const [isMouseOver, setIsMouseOver] = useState(false)
  const searchText = useSelector(getSearchText)
  const displayText = `${text} `
  const searchHighlightPatterns = useMemo(() => searchText
      ? searchText.split(/\s/)
      : [],
    [searchText])

  const handleClick = useCallback(() => {
    dispatch(setPlayerSelectedWordId(id))
  }, [id, dispatch])

  const handleMouseOver = useCallback(() => {
    setIsMouseOver(true)
  }, [])

  const handleMouseOut = useCallback(() => {
    setIsMouseOver(false)
  }, [])

  const handleMatch = useCallback(({ text, index }: {
    text: string
    index: number
  }) => <span
    key={`${text}-${index}`}
    className={styles.searchMatch}
  >
    {text}
  </span>, [])

  return (
    <span
      className={classNames(styles.wrapper, {
        [styles.hover]: isMouseOver,
        [styles.passed]: isPassed,
        [styles.highlighted]: isActive,
        [styles.editing]: isEditing,
        [styles.edited]: isEdited,
        [styles.searchSelected]: isSearchSelected
      })}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
      onClick={handleClick}
    >
        {isMatchSearch
          ? <Componentify
            text={displayText}
            className={styles.text}
            patterns={searchHighlightPatterns}
            onMatch={handleMatch}
          />
          : <span className={styles.text}>{displayText}</span>
        }
      {isMouseOver && (
        <WordTooltip
          wordId={id}
          text={text}
          startTime={startTime}
          endTime={endTime}
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
        />
      )
      }
      </span>
  )
})