import React, { useEffect, useMemo, useState } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { LoginCallback, useOktaAuth } from '@okta/okta-react'
import { Routes } from 'routes/constants'
import { Loader } from 'components/common/feedback/Loader'
import { LoginBox } from 'components/common/auth/LoginBox'
import styles from './styles.module.css'

export const Login: React.FC = () => {
  const { authState } = useOktaAuth()
  const [isLoading, setIsLoading] = useState(false)
  const { search, state } = useLocation()
  const code = useMemo(() => new URLSearchParams(search).get('code'), [search])

  useEffect(() => {
    if (code) {
      setIsLoading(true)
    }
  }, [code])

  if (!authState) {
    return <LoginCallback />
  }

  if (authState.isAuthenticated) {
    return <Navigate to={state || Routes.Index} />
  }

  if (isLoading) {
    return <Loader className={styles.loader}/>
  }

  return <LoginBox />
}
