import {
  ApiRouteNames, ApiRoutePaths,
  ApiRoutesType, HttpContentTypeHeaderValues, HTTPHeadersType, HTTPMethods
} from 'api/types'

const ApiRoutes: ApiRoutesType = {
  [ApiRouteNames.Transcribe]: {
    url: ApiRoutePaths.Transcribe,
    method: HTTPMethods.POST,
    headers: {
      [HTTPHeadersType.ContentType]:
      HttpContentTypeHeaderValues.ApplicationJSON
    }
  },
  [ApiRouteNames.GetTranscriptionContent]: {
    url: ApiRoutePaths.GetTranscription,
    method: HTTPMethods.GET,
    headers: {
      [HTTPHeadersType.ContentType]:
      HttpContentTypeHeaderValues.ApplicationJSON
    }
  },
  [ApiRouteNames.Summarise]: {
    url: ApiRoutePaths.Summarise,
    method: HTTPMethods.POST,
    headers: {
      [HTTPHeadersType.ContentType]:
      HttpContentTypeHeaderValues.ApplicationJSON
    }
  },
  [ApiRouteNames.GenerateArticle]: {
    url: ApiRoutePaths.GenerateArticle,
    method: HTTPMethods.POST,
    headers: {
      [HTTPHeadersType.ContentType]:
      HttpContentTypeHeaderValues.ApplicationJSON
    }
  },
  [ApiRouteNames.GenerateSEOTags]: {
    url: ApiRoutePaths.GenerateSEOTags,
    method: HTTPMethods.POST,
    headers: {
      [HTTPHeadersType.ContentType]:
      HttpContentTypeHeaderValues.ApplicationJSON
    }
  },
  [ApiRouteNames.GenerateHeadline]: {
    url: ApiRoutePaths.GenerateHeadline,
    method: HTTPMethods.POST,
    headers: {
      [HTTPHeadersType.ContentType]:
      HttpContentTypeHeaderValues.ApplicationJSON
    }
  },
  [ApiRouteNames.UploadFile]: {
    url: ApiRoutePaths.UploadFile,
    method: HTTPMethods.POST,
    headers: {
      [HTTPHeadersType.ContentType]:
      HttpContentTypeHeaderValues.MultipartFormData
    }
  },
  [ApiRouteNames.GetWriteSignedUrl]: {
    url: ApiRoutePaths.GetWriteSignedUrl,
    method: HTTPMethods.GET,
    headers: {
      [HTTPHeadersType.ContentType]:
      HttpContentTypeHeaderValues.ApplicationJSON
    }
  },
  [ApiRouteNames.GetReadSignedUrl]: {
    url: ApiRoutePaths.GetReadSignedUrl,
    method: HTTPMethods.GET,
    headers: {
      [HTTPHeadersType.ContentType]:
      HttpContentTypeHeaderValues.ApplicationJSON
    }
  },
  [ApiRouteNames.GetTranscriptions]: {
    url: ApiRoutePaths.GetTranscriptions,
    method: HTTPMethods.GET,
    headers: {
      [HTTPHeadersType.ContentType]:
      HttpContentTypeHeaderValues.ApplicationJSON
    }
  },
  [ApiRouteNames.GetTranscription]: {
    url: ApiRoutePaths.Transcription,
    method: HTTPMethods.GET,
    headers: {
      [HTTPHeadersType.ContentType]:
      HttpContentTypeHeaderValues.ApplicationJSON
    }
  },
  [ApiRouteNames.EditTranscription]: {
    url: ApiRoutePaths.Transcription,
    method: HTTPMethods.PUT,
    headers: {
      [HTTPHeadersType.ContentType]:
      HttpContentTypeHeaderValues.ApplicationJSON
    }
  },
  [ApiRouteNames.DeleteTranscription]: {
    url: ApiRoutePaths.Transcription,
    method: HTTPMethods.DELETE,
    headers: {
      [HTTPHeadersType.ContentType]:
      HttpContentTypeHeaderValues.ApplicationJSON
    }
  },
  [ApiRouteNames.GetTranscriptionMessages]: {
    url: ApiRoutePaths.GetTranscriptionMessages,
    method: HTTPMethods.GET,
    headers: {
      [HTTPHeadersType.ContentType]:
      HttpContentTypeHeaderValues.ApplicationJSON
    }
  },
}

export default ApiRoutes
