export enum TranscriptionStatus {
  Transcribing = 'transcribing',
  Transcribed = 'transcribed',
  Failed = 'failed',
  Expiring = 'expiring',
}

export enum TranscriptionLibraryItemDataKeys {
  Id = 'id',
  Name = 'name',
  Duration = 'duration',
  Status = 'status',
  CreatedAt = 'createdAt',
  ExpireAt = 'expireAt',
  Actions = 'actions',
}

export interface TranscriptionLibraryItem {
  id: string
  requestId: string
  name: string
  fileName?: string
  status?: TranscriptionStatus
  duration?: number | null
  createdAt?: string
  expireAt?: string
}

export type TranscriptionLibraryItemsById = Record<string, TranscriptionLibraryItem>

export interface TranscriptionLibraryStateType {
  editedItemId: string | null
  itemsById: TranscriptionLibraryItemsById
}