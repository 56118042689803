import React, { useCallback, useEffect, useState } from 'react'
import classNames from 'classnames'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Loaders } from 'redux/reducers/loaders/types'
import { RootState } from 'redux/types/store'
import { TranscriptContentTabs } from 'components/audio-pipeline/TranscriptPageTabs/constants'
import { Card } from 'components/common/data-display/Card'
import { AudioPlayerSection } from 'components/audio-pipeline/AudioPlayerSection'
import { TranscriptPageTabs } from 'components/audio-pipeline/TranscriptPageTabs'
import { TranscriptTextContent } from 'components/audio-pipeline/TranscriptPageTabs/TranscriptTextContent'
import { TranscriptAdditionalContent } from 'components/audio-pipeline/TranscriptPageTabs/TranscriptAdditionalContent'
import { fetchTranscription, fetchTranscriptions } from 'redux/actions/transcription-library'
import { getIsLoading } from 'redux/selectors/loaders'
import { getSelectedTranscriptActionTab } from 'redux/selectors/audio-pipeline'
import { getTranscriptionLibraryItems } from 'redux/selectors/transcription-library'
import styles from './styles.module.css'

export const Transcript: React.FC = () => {
  const dispatch = useDispatch()
  const { id } = useParams()
  const transcriptions = useSelector(getTranscriptionLibraryItems)
  const [activeKey, setActiveKey] = useState<string>(TranscriptContentTabs.Transcript)
  const activeActionTab = useSelector(getSelectedTranscriptActionTab)
  const isLoading = useSelector((state: RootState) => getIsLoading(state, Loaders.TranscriptionData))

  const handleChange = useCallback((id: string) => {
    setActiveKey(id)
  }, [])

  useEffect(() => {
    if (!transcriptions.length) {
      dispatch(fetchTranscriptions())
    }
  }, [dispatch, transcriptions])

  useEffect(() => {
    if (id) {
      dispatch(fetchTranscription(id))
    }
  }, [id, dispatch])

  return (
    <div className={styles.wrapper}>
      <AudioPlayerSection />
      <TranscriptPageTabs activeKey={activeKey} onChange={handleChange} />
      <Card
        isLoading={isLoading}
        contentWrapperClassName={styles.content}
        className={classNames(styles.cardWrapper, styles[activeKey], {
          [styles.expanded]: !!activeActionTab
        })}
      >
        <TranscriptTextContent
          isVisible={activeKey === TranscriptContentTabs.Transcript}
        />
        {activeKey === TranscriptContentTabs.AdditionalOutput
          && <TranscriptAdditionalContent />}
      </Card>
    </div>
  )
}