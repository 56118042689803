import { TranscriptSearchMode } from 'redux/reducers/transcript-search/types'
import {
  TranscriptActionOptions
} from 'components/audio-pipeline/TranscriptPageTabs/TranscriptTextContent/TranscriptOptionsBar/types'

export const SEARCH_MODE_BY_SELECTED_OPTION: Record<
  TranscriptActionOptions,
  TranscriptSearchMode | null
> = {
  [TranscriptActionOptions.Search]: TranscriptSearchMode.Search,
  [TranscriptActionOptions.SearchAndReplace]: TranscriptSearchMode.SearchAndReplace,
  [TranscriptActionOptions.Speakers]: null
}