import React from 'react'
import classNames from 'classnames'
import NewsTranscribeLogo from 'assets/img/news-transcribe-logo.svg'
import styles from './styles.module.css'

interface Props {
  className?: string
  onClick?: () => void
}

export const Logo: React.FC<Props> = ({
  onClick,
  className
}) => (
  <img
    src={NewsTranscribeLogo}
    onClick={onClick}
    className={classNames(styles.wrapper, className)}
    alt='news-transcribe-logo'
  />
)