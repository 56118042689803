import { AxiosProgressEvent } from 'axios'

export enum HTTPHeadersType {
  ContentType = 'Content-Type',
  Authorization = 'Authorization',
  XGoogMetaUserId = 'x-goog-meta-user-id',
  XGoogMetaDisplayName = 'x-goog-meta-display-name',
}

export enum HttpContentTypeHeaderValues {
  ApplicationJSON = 'application/json',
  MultipartFormData = 'multipart/form-data'
}

type HTTPContentTypeHeader = Record<HTTPHeadersType.ContentType, HttpContentTypeHeaderValues>
type HTTPAuthorizationHeader = Record<HTTPHeadersType.Authorization, string>

export type HTTPHeaders = HTTPContentTypeHeader | HTTPAuthorizationHeader

export type FetchParams = Record<string, any>
export type FetchBody = Record<string, any>

export enum ApiRouteNames {
  Transcribe = 'transcribe',
  GetTranscriptionContent = 'getTranscriptionContent',
  Summarise = 'summarise',
  GenerateArticle = 'generateArticle',
  GenerateSEOTags = 'generateSEOTags',
  GenerateHeadline = 'generateHeadline',
  UploadFile = 'uploadFile',
  GetWriteSignedUrl = 'getWriteSignedUrl',
  GetReadSignedUrl = 'getReadSignedUrl',
  GetTranscriptionMessages = 'getTranscriptionMessages',
  GetTranscriptions = 'getTranscriptions',
  GetTranscription = 'getTranscriptionById',
  DeleteTranscription = 'deleteTranscription',
  EditTranscription = 'editTranscription'
}

export enum ApiRoutePaths {
  Transcribe = '/audio-pipeline/transcribe',
  Summarise = '/audio-pipeline/summarise',
  GenerateArticle = '/audio-pipeline/generate-article',
  GenerateSEOTags = '/audio-pipeline/generate-seo-tags',
  GenerateHeadline = '/audio-pipeline/generate-headline',
  GetTranscription = '/audio-pipeline/transcription/:id',
  UploadFile = '/upload',
  GetWriteSignedUrl = '/audio-pipeline/upload/get-signed-url',
  GetReadSignedUrl = '/audio-pipeline/download/get-signed-url',
  GetTranscriptionMessages = '/audio-pipeline/messages/transcriptions',
  GetTranscriptions = '/transcriptions',
  Transcription = '/transcriptions/:id'
}

export interface FetchArgsType {
  body?: FetchBody
  urlParams?: FetchParams
  queryParams?: FetchParams
  formData?: FormData
  onUploadProgress?: (progressEvent: AxiosProgressEvent) => void
}

export interface ReplaceUrlArgsType {
  url: string
  urlParams?: FetchParams
}

export enum HTTPMethods {
  GET = 'GET',
  POST = 'POST',
  DELETE = 'DELETE',
  PUT = 'PUT',
  PATCH = 'PATCH',
}

export interface ApiRoute {
  url: ApiRoutePaths
  method: HTTPMethods,
  headers: HTTPHeaders
}

export type ApiRoutesType = Record<string, ApiRoute>

export type WrappedApiCallback = (args?: FetchArgsType) => Promise<any>