import React from 'react'
import { useIntl } from 'react-intl'
import classNames from 'classnames'
import { Empty } from 'antd'
import styles from './styles.module.css'

interface Props {
  className?: string,
  description?: string | null
  shouldRenderDescription?: boolean
}

export const NoData: React.FC<Props> = ({
  className,
  description,
  shouldRenderDescription
}) => {
  const { formatMessage } = useIntl()
  const defaultDescription = formatMessage({
    id: 'component.common.no-data.description'
  })
  const descriptionText = description || defaultDescription
  const text = shouldRenderDescription ? descriptionText : null

  return (
    <Empty
      description={text}
      className={classNames(styles.wrapper, className)}
    />
  )
}