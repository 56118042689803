import React from 'react'
import classNames from 'classnames'
import { TranscriptContent } from 'components/audio-pipeline/TranscriptContent'
import styles from './styles.module.css'

interface Props {
  isVisible: boolean
}

export const TranscriptTextContent: React.FC<Props> = ({
  isVisible
}) => (
  <div className={classNames(styles.wrapper, {
    [styles.hidden]: !isVisible
  })}>
    <TranscriptContent />
  </div>
)