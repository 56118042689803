import React, { useCallback, useEffect } from 'react'
import { useSelector } from 'react-redux'
import classNames from 'classnames'
import { Typography } from 'antd'
import { TranscriptWord } from 'components/audio-pipeline/TranscriptContent/TranscriptWord'
import { TranscriptionWord } from 'components/audio-pipeline/TranscriptContent/types'
import {
  getPlayerCurrentTime,
  getSpeakerNames
} from 'redux/selectors/audio-pipeline'
import { replaceSpeakerNames } from 'utils/audio-pipeline'
import { formatPlaybackWordTime } from 'utils/audio-pipeline/audio-player'
import styles from './styles.module.css'

const { Paragraph } = Typography

interface Props {
  speaker: string | null
  index: number
  startTime: number
  endTime: number
  words: TranscriptionWord[]
  className?: string
  searchMatchedWordIds: string[]
  selectedSearchGroupWords: string[]
  editedWordId: string | null
  currentWordId: string | null
  editedWordsById: Record<string, TranscriptionWord>
  onParagraphActive: (index: number) => void
}

export const SpeakerParagraph: React.FC<Props> = React.memo(({
  speaker,
  index,
  words,
  startTime,
  endTime,
  className,
  searchMatchedWordIds,
  selectedSearchGroupWords,
  editedWordId,
  onParagraphActive,
  editedWordsById,
}) => {
  const playerCurrentTime = useSelector(getPlayerCurrentTime)
  const speakerNames = useSelector(getSpeakerNames)
  const name = speaker
    ? replaceSpeakerNames({ text: speaker, names: speakerNames })
    : null
  const currentTimeInMs = playerCurrentTime * 1000
  const isParagraphActive = currentTimeInMs >= startTime && currentTimeInMs <= endTime
  const startTimeText = formatPlaybackWordTime(startTime)

  useEffect(() => {
    if (isParagraphActive) {
      onParagraphActive(index)
    }
  }, [isParagraphActive, index, onParagraphActive])

  const renderWord = useCallback(({ id, text, startTime, endTime }: TranscriptionWord) => {
    const isPassed = currentTimeInMs >= startTime
    const isActive = startTime <= currentTimeInMs && currentTimeInMs <= endTime
    const isMatchSearch = searchMatchedWordIds.includes(id)
    const isEditing = id === editedWordId
    const isSearchSelected=selectedSearchGroupWords.includes(id)
    const editedWord = editedWordsById[id]

    return (
      <TranscriptWord
        id={id}
        key={id}
        text={editedWord?.text || text }
        isPassed={isPassed}
        isActive={isActive}
        isMatchSearch={isMatchSearch}
        isSearchSelected={isSearchSelected}
        isEditing={isEditing}
        isEdited={!!editedWord}
        startTime={startTime}
        endTime={endTime}
      />
    )
  }, [
    currentTimeInMs,
    searchMatchedWordIds,
    editedWordId,
    editedWordsById,
    selectedSearchGroupWords
  ])

  return (
    <div className={classNames(styles.wrapper, {
      [styles.monologStart]: !!speaker
    }, className)}>
      {name && <span className={styles.timestamp}>{startTimeText}</span>}
      {name && <span className={styles.speaker}>{name}</span>}
      <Paragraph className={styles.text}>{words.map(renderWord)}</Paragraph>
    </div>
  )
})