import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ReducersType } from 'redux/reducers/types'
import { ModalInitialState } from 'redux/reducers/modals/constants'
import { ToggleModalPayload } from 'redux/actions/modals/types'

export const modals = createSlice({
  name: ReducersType.Modals,
  initialState: ModalInitialState,
  reducers: {
    openModal: (state, { payload: { id } }: PayloadAction<ToggleModalPayload>) => {
      state.openModals.push(id)
    },
    closeModal: (state, { payload: { id } }: PayloadAction<ToggleModalPayload>) => {
      state.openModals = state.openModals.filter(modalId => modalId !== id)
    }
    }
  })