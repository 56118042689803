import { OPEN_MODAL, CLOSE_MODAL } from 'redux/actions/modals/constants'
import { createAction } from '@reduxjs/toolkit'
import { PrepareToggleModalActionType } from 'redux/actions/modals/types'
import { ModalIds } from 'redux/reducers/modals/types'

export const openModal = createAction<PrepareToggleModalActionType>(
  OPEN_MODAL,
  (id: ModalIds) => ({
    payload: { id }
  })
)

export const closeModal = createAction<PrepareToggleModalActionType>(
  CLOSE_MODAL,
  (id: ModalIds) => ({
    payload: { id }
  })
)