import React, { MouseEventHandler, useCallback } from 'react'
import { IconButton } from 'components/common/general/IconButton'
import { CustomIconType } from 'components/common/general/CustomIcon/types'
import { useDispatch } from 'react-redux'
import { deleteTranscription, editTranscription } from 'redux/actions/transcription-library'
import styles from './styles.module.css'

interface Props {
  rowId: string
  disabled?: boolean
}

export const TranscriptionLibraryItemActions: React.FC<Props> = ({
  rowId,
  disabled
}) => {
  const dispatch = useDispatch()
  const handleEdit = useCallback<MouseEventHandler<HTMLButtonElement>>((e) => {
    e.stopPropagation()
    dispatch(editTranscription(rowId))
  }, [dispatch, rowId])

  const handleDelete = useCallback<MouseEventHandler<HTMLButtonElement>>((e) => {
    e.stopPropagation()
    dispatch(deleteTranscription(rowId))
  }, [dispatch, rowId])

  return (
    <div className={styles.wrapper}>
      <IconButton
        iconType={CustomIconType.Edit}
        className={styles.button}
        disabled={disabled}
        onClick={handleEdit}
      />
      <IconButton
        iconType={CustomIconType.Delete}
        className={styles.button}
        disabled={disabled}
        onClick={handleDelete}
      />
    </div>
  )
}