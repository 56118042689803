import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { CustomIconType } from 'components/common/general/CustomIcon/types'
import { TranscriptActionOptions } from 'components/audio-pipeline/TranscriptPageTabs/TranscriptTextContent/TranscriptOptionsBar/types'
import { TranscriptionSearchBar } from 'components/audio-pipeline/TranscriptPageTabs/TranscriptTextContent/TranscriptOptionsBar/SearchBar'
import { IconButton } from 'components/common/general/IconButton'
import { SpeakerNames } from 'components/audio-pipeline/SpeakerNames'
import { setSelectedTranscriptActionTab } from 'redux/actions/audio-pipeline'
import { resetState } from 'redux/actions/transcript-search'
import styles from './styles.module.css'

interface Props {
  selectedOption: TranscriptActionOptions
}

export const TabContent: React.FC<Props> = ({
  selectedOption
}) => {
  const dispatch = useDispatch()

  const handleClose = useCallback(() => {
    dispatch(resetState())
    dispatch(setSelectedTranscriptActionTab(null))
  }, [dispatch])

  const renderContent = useCallback(() => {
    switch (selectedOption) {
      case TranscriptActionOptions.Search:
      case TranscriptActionOptions.SearchAndReplace:
        return <TranscriptionSearchBar />
      case TranscriptActionOptions.Speakers:
        return <SpeakerNames />
      default:
        return null
    }
  }, [selectedOption])

  return (
    <div className={styles.wrapper}>
      {renderContent()}
      <IconButton
        iconType={CustomIconType.Close}
        className={styles.closeButton}
        onClick={handleClose}
      />
    </div>
  )
}