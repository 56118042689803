import { RootState } from 'redux/types/store'
import {
  TranscriptionSpeakerMonolog,
  TranscriptionSpeakerParagraph
} from 'components/audio-pipeline/TranscriptContent/types'
import { Loaders } from 'redux/reducers/loaders/types'
import { createSelector } from 'reselect'
import { replaceSpeakerNames } from 'utils/audio-pipeline'
import { getIsLoading } from 'redux/selectors/loaders'

export const getSelectedLanguage = (state: RootState) => state.audioPipeline.language
export const getFileUrl = (state: RootState) => state.audioPipeline.fileUrl
export const getTranscription = (state: RootState) => state.audioPipeline.transcription
export const getSummarisedText = (state: RootState) => state.audioPipeline.summarisedText
export const getHeadlineTailoringText = (state: RootState) => state.audioPipeline.headlineTailoringText
export const getGeneratedHeadlineText = (state: RootState) => state.audioPipeline.generatedHeadlineText
export const getGeneratedArticleText = (state: RootState) => state.audioPipeline.generatedArticleText
export const getSEOTagsText = (state: RootState) => state.audioPipeline.SEOTagsText
export const getSpeakerNamesText = (state: RootState) => state.audioPipeline.speakerNamesFieldText
export const getFileUploadProgress = (state: RootState) => state.audioPipeline.fileUploadProgress
export const getSelectedFile = (state: RootState) => state.audioPipeline.file
export const getFileError = (state: RootState) => state.audioPipeline.fileError
export const getPlayerCurrentTime = (state: RootState) => state.audioPipeline.playerCurrentTime
export const getPlayerCurrentWordId = (state: RootState) => state.audioPipeline.playerCurrentWordId
export const getIsPlaybackStarted = (state: RootState) => state.audioPipeline.isPlaybackStarted
export const getEditedWordId = (state: RootState) => state.audioPipeline.editedWordId
export const getEditedWordText = (state: RootState) => state.audioPipeline.editedWordText
export const getEditedWords = (state: RootState) => state.audioPipeline.editedWordsById
export const getSpeakerNames = (state: RootState) => state.audioPipeline.speakerNames
export const getSelectedTranscriptActionTab = (state: RootState) => state.audioPipeline.selectedTranscriptActionTab
export const getTranscriptAudioUrl = (state: RootState) => state.audioPipeline.transcriptAudioUrl

export const getTranscribedText = createSelector(
  getEditedWords,
  getTranscription,
  (editedWords, data: TranscriptionSpeakerMonolog[]) => data
    .map(({ speaker, paragraphs }) => paragraphs.map(({ wordTimestamps }) => {

      const wordsText = wordTimestamps
        .map(({ id, text }) => editedWords[id]?.text || text)
        .join(' ')

      return `${speaker}: ${wordsText}`
    })).flat().join('\n'))

export const getFormattedTranscribedText = createSelector(
  getTranscribedText,
  getSpeakerNames,
  (text, names) => replaceSpeakerNames({ text, names })
)

export const getTranscriptionParagraphs = createSelector(
  getTranscription, (data): TranscriptionSpeakerParagraph[] => data
    .map(({ speaker, paragraphs }) => paragraphs
      .map(({ wordTimestamps, startTime, endTime }, index) => ({
        speaker: index === 0 ? speaker : null,
        wordTimestamps,
        startTime,
        endTime
      }))
    ).flat()
)

export const getTranscriptionOriginalWords = createSelector(getTranscriptionParagraphs,
  paragraphs => paragraphs
    .map(({ wordTimestamps }) => wordTimestamps)
    .flat())

export const getTranscriptionWords = createSelector(
  getTranscriptionOriginalWords,
  getEditedWords,
  (words, editedWordsById) =>
    words.map(({ id, text, ...rest }) => ({
      ...rest,
      id,
      text: editedWordsById[id]?.text || text,
      originalText: text
    }))
)

export const getSelectedEditedWord = createSelector(
  getTranscriptionWords,
  getEditedWordId,
  (words, editedWordId) => words.find(({ id }) => id === editedWordId)
)

export const getIsTranscribeDisabled = createSelector(
  getFileUrl,
  getSelectedFile,
  getFileError,
  (state: RootState) => getIsLoading(state, Loaders.TranscriptionData),
  (state: RootState) => getIsLoading(state, Loaders.UploadFile),
  (fileUrl, selectedFile, error, isTranscribing, isUploading) => {
    const isSourceSelected = fileUrl || selectedFile
    const isLoading = isTranscribing || isUploading

    return !isSourceSelected || isLoading || !!error
  }
)