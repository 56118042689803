import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ReducersType } from 'redux/reducers/types'
import {
  ConsumeTranscriptionsPayload,
  UpdateTranscriptionPayload,
  DeleteTranscriptionPayload,
  SetEditTranscriptionIdPayload
} from 'redux/actions/transcription-library/types'
import { TranscriptionLibraryInitialState } from 'redux/reducers/transcription-library/constants'
import { mergeTranscriptionLibraryItemsById } from 'utils/audio-pipeline/mappers/transcription-library'
import omit from 'lodash/omit'

export const transcriptionLibrary = createSlice({
  name: ReducersType.TranscriptionLibrary,
  initialState: TranscriptionLibraryInitialState,
  reducers: {
    consumeTranscriptions: (state, { payload: { data } }: PayloadAction<ConsumeTranscriptionsPayload>) => {
      state.itemsById = mergeTranscriptionLibraryItemsById({
        currentItems: Object.values(state.itemsById),
        newItems: data
      })
    },
    updateTranscription: (state, { payload: { id, name } }: PayloadAction<UpdateTranscriptionPayload>) => {
      state.itemsById[id].name = name
    },
    removeTranscription: (state, { payload: { id } }: PayloadAction<DeleteTranscriptionPayload>) => {
      state.itemsById = omit(state.itemsById, id)
    },
    setEditedTranscriptionId: (state, { payload: { id } }: PayloadAction<SetEditTranscriptionIdPayload>) => {
      state.editedItemId = id
    }
  }
})