import { TranscriptionWord } from 'components/audio-pipeline/TranscriptContent/types'
import { TranscriptionSearchMatchWord } from 'redux/reducers/transcript-search/types'

export const getSearchTextMatches = ({
  words,
  searchText
}: {
  words: TranscriptionWord[],
  searchText: string
}): TranscriptionSearchMatchWord[] => {
  const formattedSearchText = searchText.trim().toLowerCase()
  const searchTextWords = formattedSearchText.split(/\s/)

  return words.reduce(
    (acc: TranscriptionSearchMatchWord[], word, index) => {
      const { text } = word
      const searchMatch = searchTextWords
        .find(searchWordsText => text.toLowerCase().indexOf(searchWordsText) !== -1)

      if (!searchMatch) {
        return acc
      }

      const searchMatchWordIndex = searchTextWords.indexOf(searchMatch)
      const sliceStartIndex = index - searchMatchWordIndex
      const sliceEndIndex = sliceStartIndex + searchTextWords.length

      const matchContext = words.slice(sliceStartIndex, sliceEndIndex)
      const matchContextText = matchContext.map(({ text }) => text)
        .join(' ')
        .toLowerCase()
      const groupId = matchContext
        .map(({ id }) => id)
        .join('-')
      const isMatchContext = matchContextText.indexOf(formattedSearchText) !== -1

      if (isMatchContext) {
        acc.push({
          groupId,
          ...word
        })
      }

      return acc
    }, [])
}
