import React, { useCallback } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { CustomIconType } from 'components/common/general/CustomIcon/types'
import { Button } from 'components/common/general/Button'
import { CustomIcon } from 'components/common/general/CustomIcon'
import { copyToClipboard } from 'redux/actions/audio-pipeline'
import { getFormattedTranscribedText } from 'redux/selectors/audio-pipeline'
import styles from './styles.module.css'

export const CopyButton: React.FC = () => {
  const dispatch = useDispatch()
  const { formatMessage } = useIntl()
  const formattedTranscriptText = useSelector(getFormattedTranscribedText)
  const text = formatMessage({
    id: 'component.audio-pipeline.copy-button.label'
  })

  const handleClick = useCallback(() => {
    dispatch(copyToClipboard(formattedTranscriptText))
  }, [dispatch, formattedTranscriptText])

  return (
    <Button
      type='primary'
      text={text}
      className={styles.wrapper}
      icon={<CustomIcon
        className={styles.icon}
        type={CustomIconType.Copy}
      />}
      onClick={handleClick}
    />
  )
}