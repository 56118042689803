import React, { useCallback } from 'react'
import { Logo } from 'components/layouts/common/Header/Logo'
import { UserInfo } from 'components/layouts/common/Header/UserInfo'
import { useNavigate } from 'react-router'
import { Routes } from 'routes/constants'
import styles from './styles.module.css'

export const Header: React.FC = () => {
  const navigate = useNavigate()

  const handleClick = useCallback(() => {
    navigate(Routes.Index)
  }, [navigate])

  return (
    <div className={styles.wrapper}>
      <Logo
        className={styles.logo}
        onClick={handleClick}
      />
      <UserInfo />
    </div>
  )
}