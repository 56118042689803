import React from 'react'
import { FileUpload } from 'components/audio-pipeline/FileUpload'
import { Transcriptions } from 'components/audio-pipeline/Transcriptions'
import styles from './styles.module.css'

export const AudioPipeline: React.FC = () => (
  <div className={styles.wrapper}>
    <FileUpload />
    <Transcriptions />
  </div>
)
