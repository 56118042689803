import React, { useCallback } from 'react'
import { Input as BaseInput, InputProps } from 'antd'
import classNames from 'classnames'
import { CloseOutlined } from '@ant-design/icons'
import styles from './styles.module.css'

interface Props extends InputProps {
  className?: string
  renderClearIcon?: () => React.ReactNode
}

export const Input: React.FC<Props> = ({
  value,
  placeholder,
  className,
  allowClear,
  autoFocus,
  onChange
}) => {
  const clearIconRenderer = useCallback(() => allowClear && {
      clearIcon: allowClear && <CloseOutlined />
    }, [allowClear])

  return (
    <BaseInput
      allowClear={clearIconRenderer()}
      autoFocus={autoFocus}
      value={value}
      placeholder={placeholder}
      rootClassName={classNames(styles.wrapper, className)}
      onChange={onChange}
    />
  )
}