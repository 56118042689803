const messages = {
  'component.common.user-greeting.text': '{email}',
  'component.common.auth.logout': 'Logout',
  'component.common.header.title': 'AI Audio Pipeline',
  'component.common.submit.text': 'Submit',
  'component.common.label.or': 'OR',
  'component.common.copy-to-clipboard.success': 'Copied to clipboard',
  'component.common.help-link.text': `Help & FAQ's`,
  'component.common.no-data.description': 'No Data'
}

export default messages
