import { all, call, cancel, delay, fork, put, race, select, take, takeLatest } from 'redux-saga/effects'
import { Channel, channel } from 'redux-saga'
import axios from 'axios'
import { PayloadAction } from '@reduxjs/toolkit'
import { LanguageSelectOptionIds } from 'components/audio-pipeline/FileUpload/LanguageSelect/types'
import {
  ToggleEditWordPayload,
  CopyToClipboardPayload,
  FetchTranscriptAudioUrlPayload
} from 'redux/actions/audio-pipeline/types'
import { Loaders } from 'redux/reducers/loaders/types'
import { HTTPHeadersType, HTTPMethods } from 'api/types'
import {
  COPY_TO_CLIPBOARD,
  FETCH_TRANSCRIPT_AUDIO_URL,
  GENERATE_ARTICLE,
  GENERATE_HEADLINE,
  GENERATE_SEO_TAGS,
  START_EDIT_WORD,
  START_PLAYBACK,
  STOP_EDIT_WORD,
  STOP_PLAYBACK,
  SUMMARISE,
  TRANSCRIBE,
  UPLOAD_FILE
} from 'redux/actions/audio-pipeline/constants'
import { AUDIO_PLAYBACK_UPDATE_INTERVAL } from 'redux/sagas/audio-pipeline/constants'
import { AUDIO_PLAYER_ID } from 'components/audio-pipeline/FileUpload/FileUploadField/constants'
import api from 'api'
import {
  getEditedWordText,
  getFileUrl,
  getFormattedTranscribedText,
  getHeadlineTailoringText,
  getSelectedEditedWord,
  getSelectedFile,
  getSelectedLanguage
} from 'redux/selectors/audio-pipeline'
import {
  setFileUploadProgress,
  setGeneratedArticleText,
  setGeneratedHeadlineText,
  setPlayerCurrentTime,
  setSEOTagsText,
  setSummarisedText,
  stopPlayback,
  clearInputFields,
  setEditedWordId,
  setEditedWordText,
  setEditedWord,
  revertEditedWord,
  setSelectedFile,
  setTranscriptAudioUrl
} from 'redux/actions/audio-pipeline'
import { startLoading, stopLoading } from 'redux/actions/loaders'
import { handleError } from 'redux/actions/errors'
import { addNotification } from 'redux/actions/notifications'
import { NotificationType } from 'redux/reducers/notifications/types'
import { TranscriptionWord } from 'components/audio-pipeline/TranscriptContent/types'
import { intl } from 'utils/common/intl'
import { NotificationIds } from 'components/notification/Notifications/constants'
import { oktaAuth } from 'utils/auth/auth-client'
import {
  consumeTranscriptions,
  startPollingTranscriptions
} from 'redux/actions/transcription-library'
import { TranscriptionLibraryItem, TranscriptionStatus } from 'redux/reducers/transcription-library/types'
import { getTranscriptionLibraryItemById } from 'redux/selectors/transcription-library'

export function* watchUploadFileChannel(ch: Channel<any>) {
  while (true) {
    const progress: number = yield take(ch)
    yield put(setFileUploadProgress(progress))
  }
}

function* handleUploadFile() {
  const file: File | null = yield select(getSelectedFile)
  const lang: LanguageSelectOptionIds = yield select(getSelectedLanguage)
  const ch: Channel<any> = yield call(channel)
  let task = null

  if (!file) {
    return
  }

  yield put(startLoading(Loaders.UploadFile))
  yield put(setFileUploadProgress(0))

  try {
    const { data } = yield call(api.getWriteSignedUrl, {
      queryParams: {
        lang,
        fileName: file.name
      }
    })

    if (data) {
      const { url, id } = data
      const { email } = yield call([oktaAuth, oktaAuth.getUser])
      // @ts-ignore
      task = yield fork(watchUploadFileChannel, ch)

      yield call([axios, axios.request], {
        method: HTTPMethods.PUT,
        url: url,
        data: file,
        headers: {
          [HTTPHeadersType.ContentType]: file.type,
          [HTTPHeadersType.XGoogMetaUserId]: email,
          [HTTPHeadersType.XGoogMetaDisplayName]: file.name
        },
        onUploadProgress: ({ progress }) => {
          if (progress) {
            ch.put(progress)
          }
        }
      })

      yield put(consumeTranscriptions([{
        id,
        requestId: id,
        name: file.name || id,
        duration: null,
        createdAt: Date(),
        status: TranscriptionStatus.Transcribing
      }]))

      yield put(setSelectedFile(null))
      yield put(startPollingTranscriptions())
    }
  } catch (err: any) {
    yield put(handleError(err))
  } finally {
    if (task) {
      yield cancel(task)
    }

    yield put(stopLoading(Loaders.UploadFile))
  }
}

function* handleTranscribeUrl() {
  const url: string = yield select(getFileUrl)
  const lang: LanguageSelectOptionIds = yield select(getSelectedLanguage)

  try {
    const { data } = yield call(api.transcribe, {
      body: {
        url,
        lang: lang === LanguageSelectOptionIds.direct
          ? lang
          : LanguageSelectOptionIds.en_GB
      }
    })

    if (data && data.id) {
      const { id } = data

      yield put(consumeTranscriptions([{
        id,
        requestId: id,
        name: url,
        duration: null,
        createdAt: Date(),
        status: TranscriptionStatus.Transcribing
      }]))
      yield put(startPollingTranscriptions())
    }
  } catch (err: any) {
    yield put(handleError(err))
  }
}

function* handleTranscribe() {
  const url: string = yield select(getFileUrl)
  const file: File | null = yield select(getSelectedFile)

  yield put(clearInputFields())

  if (url) {
    yield call(handleTranscribeUrl)
  }

  if (file) {
    yield call(handleUploadFile)
  }
}

function* handleSummarise() {
  const text: string = yield select(getFormattedTranscribedText)

  yield put(startLoading(Loaders.Summarise))

  try {
    const { data } = yield call(api.summarise, {
      body: {
        text
      }
    })

    if (data) {
      yield put(setSummarisedText(data))
    }
  } catch (err: any) {
    yield put(handleError(err))
  } finally {
    yield put(stopLoading(Loaders.Summarise))
  }
}

function* handleGenerateArticle() {
  const text: string = yield select(getFormattedTranscribedText)

  yield put(startLoading(Loaders.GenerateArticle))

  try {
    const { data } = yield call(api.generateArticle, {
      body: {
        text
      }
    })

    if (data) {
      yield put(setGeneratedArticleText(data))
    }
  } catch (err: any) {
    yield put(handleError(err))
  } finally {
    yield put(stopLoading(Loaders.GenerateArticle))
  }
}

function* handleGenerateSEOTags() {
  const text: string = yield select(getFormattedTranscribedText)

  yield put(startLoading(Loaders.GenerateSEOTags))

  try {
    const { data } = yield call(api.generateSEOTags, {
      body: {
        text
      }
    })

    if (data) {
      yield put(setSEOTagsText(data))
    }
  } catch (err: any) {
    yield put(handleError(err))
  } finally {
    yield put(stopLoading(Loaders.GenerateSEOTags))
  }
}

function* handleGenerateHeadline() {
  const text: string = yield select(getFormattedTranscribedText)
  const keyWords: string = yield select(getHeadlineTailoringText)

  yield put(startLoading(Loaders.GenerateHeadline))

  try {
    const { data } = yield call(api.generateHeadline, {
      body: {
        text,
        keyWords
      }
    })

    if (data) {
      yield put(setGeneratedHeadlineText(data))
    }
  } catch (err: any) {
    yield put(handleError(err))
  } finally {
    yield put(stopLoading(Loaders.GenerateHeadline))
  }
}

const playbackTimeupdateInterval = function* () {
  const audio = document.getElementById(AUDIO_PLAYER_ID) as HTMLAudioElement

  while (true) {
    if (!audio || audio.paused) {
      yield put(stopPlayback())

      return null
    }

    yield put(setPlayerCurrentTime(audio.currentTime))
    yield delay(AUDIO_PLAYBACK_UPDATE_INTERVAL)
  }
}

const handleStartPlaybackTimeupdate = function* () {
  yield race({
    interval: call(playbackTimeupdateInterval),
    cancel: take(STOP_PLAYBACK)
  })
}

const handleCopyToClipboard = function* ({ payload: { text } }: PayloadAction<CopyToClipboardPayload>) {
  try {
    yield call([navigator.clipboard, navigator.clipboard.writeText], text)

    yield put(addNotification({
      id: NotificationIds.CopyToClipboardSuccess,
      type: NotificationType.Success,
      message: intl.formatMessage({
        id: 'component.common.copy-to-clipboard.success'
      })
    }))
  } catch (err) {
    console.error(err)
  }
}

const handleStartEditWord = function* ({ payload: { id } }: PayloadAction<ToggleEditWordPayload>) {
  yield put(setEditedWordId(id))

  const word: TranscriptionWord | undefined = yield select(getSelectedEditedWord)

  if (word) {
    const { text } = word

    yield put(setEditedWordText(text))
  }
}

const handleApplyEdit = function* ({
  word,
  text
}: {
  word: TranscriptionWord,
  text: string
}) {
  const { id, startTime, endTime } = word

  yield put(setEditedWord({
    id,
    text,
    startTime,
    endTime
  }))
}

const handleStopEditWord = function* ({ payload: { id } }: PayloadAction<ToggleEditWordPayload>) {
  const word: TranscriptionWord | undefined = yield select(getSelectedEditedWord)
  const editedWordText: string = yield select(getEditedWordText)
  const isTextChanged = word && word.text !== editedWordText
  const shouldRevertToOriginal = word?.originalText === editedWordText
  const shouldApplyEdit = isTextChanged && !shouldRevertToOriginal

  if (shouldApplyEdit) {
    yield call(handleApplyEdit, { word, text: editedWordText })
  }

  if (shouldRevertToOriginal) {
    yield put(revertEditedWord(id))
  }

  yield put(setEditedWordId(null))
  yield put(setEditedWordText(''))
}

const handleFetchTranscriptAudioUrl = function* ({ payload: { id } }: PayloadAction<FetchTranscriptAudioUrlPayload>) {
  const item: TranscriptionLibraryItem = yield select(getTranscriptionLibraryItemById, id)

  yield put(startLoading(Loaders.TranscriptionAudioUrl))

  try {
    const { data } = yield call(api.getReadSignedUrl, {
      queryParams: {
        fileName: item?.fileName
      }
    })

    if (data?.url) {
      const { url } = data

      yield put(setTranscriptAudioUrl(url))
    }

  } catch (err) {
    console.error(err)
  } finally {
    yield put(stopLoading(Loaders.TranscriptionAudioUrl))
  }
}

export function* audioPipeline() {
  yield all([
    takeLatest(UPLOAD_FILE, handleUploadFile),
    takeLatest(TRANSCRIBE, handleTranscribe),
    takeLatest(SUMMARISE, handleSummarise),
    takeLatest(GENERATE_ARTICLE, handleGenerateArticle),
    takeLatest(GENERATE_SEO_TAGS, handleGenerateSEOTags),
    takeLatest(GENERATE_HEADLINE, handleGenerateHeadline),
    takeLatest(START_PLAYBACK, handleStartPlaybackTimeupdate),
    takeLatest(COPY_TO_CLIPBOARD, handleCopyToClipboard),
    takeLatest(START_EDIT_WORD, handleStartEditWord),
    takeLatest(STOP_EDIT_WORD, handleStopEditWord),
    takeLatest(FETCH_TRANSCRIPT_AUDIO_URL, handleFetchTranscriptAudioUrl)
  ])
}