import React from 'react'
import { LanguageSelect } from 'components/audio-pipeline/FileUpload/LanguageSelect'
import { TranscribeButton } from 'components/audio-pipeline/FileUpload/TranscribeButton'
import styles from './styles.module.css'

export const FileUploadSectionFooter: React.FC = () => (
  <div className={styles.wrapper}>
    <LanguageSelect />
    <TranscribeButton />
  </div>
)
